import React, {useState} from 'react';

class dropdown extends React.Component {

    state = {
        activeClass: false,
        open: 'open',
        title: this.props.choose[0]['title'],
        value: this.props.choose[0]['value'],
        select_id: 0
    };
    handleClick_Active = (button) => {button == 'open' ? this.setState({ activeClass: true, open: ''}) : this.setState({ activeClass: false, open: 'open'}) };
    // handleClick_choose = (id) => this.setState(console.log(id))
    handleClick_choose = (index1, id) => {this.setState({title: this.props.choose[index1]['title'], select_id: index1, value:  this.props.choose[index1]['value'] })}
    select_options = (index1) => <select defaultValue={this.props.choose[index1]['id']} name={this.props.name} id={this.props.name} style={{display: 'none'}}>
        <option value={this.props.choose[index1]['value']}>{this.props.choose[index1]['title']}</option>
    </select>

    render() {
        const chooses = this.props.choose.map((item,  index1) =>
            <React.Fragment key={index1}>
                <li data-value={index1} className="option" onClick={() => this.handleClick_choose(index1, item.id)}>{item.title}</li>
            </React.Fragment>
        );

        // const select_options = this.props.choose.map((item) =>
        //     <React.Fragment key={item.id}>
        //         <option value={item.id} hidden>{item.value}</option>
        //     </React.Fragment>
        // );

        return (<div>

            {this.select_options(this.state.select_id)}

            <div className={this.state.activeClass ? 'nice-select open' : 'nice-select'} onClick={() => this.handleClick_Active(this.state.open)} tabIndex="0">
                <span className="current">{this.state.title}</span>
                <ul className="list">
                    {/*<li data-value="0" className="option"></li>*/}
                    {chooses}
                </ul>
                <input name="os0" value={this.state.value} type="hidden" />
            </div>
        </div>
        );
    }
}

export default dropdown;
