import React from 'react';
import Book_button_croydon from '../../../access/img/Book-button-croydon-site.png';

const  BookOnline = () => {
    return (<div className="appointment">
            <h2 className="sectionTitle center">Make an Appointment</h2>
            <div className="book-text"><a href="https://pms.surreyphysio.co.uk/diary/online_booking" target="_blank"><img className="book_appointment" src={Book_button_croydon} /></a></div>
            {/*<div className="book-online-content">*/}
            {/*    <div className="back-fon"></div>*/}
            {/*    <div className="book-text"><a href="https://pms.surreyphysio.co.uk/diary/online_booking" target="_blank"><img className="book_appointment" src={Book_button_croydon} /></a></div>*/}
            {/*</div>*/}
        </div>
    );
}

export default BookOnline;