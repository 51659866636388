import React from 'react';

const  Footer = () => {
    return <footer>
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="links">
                        <div className="footerTitle">Clinics</div>
                        <ul className="links__ul">
                            <li><a href="#/">Balham Physio</a></li>
                            <li><a href="#/">Battersea Physio</a></li>
                            <li><a href="#/">Croydon Physio</a></li>
                            <li><a href="#/">Wallington Physio</a></li>
                            <li><a href="#/">Carshalton Physio</a></li>
                            <li><a href="#/">Mitcham Physio</a></li>
                            <li><a href="#/">Sutton Physio</a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="links">
                        <div className="footerTitle">Contact us</div>
                        <div className="footer__phone">
                            Tel: <b>0208 651 3315</b>
                        </div>
                        <div className="footer__phone">
                            Fax: <b>0208 685 6931</b>
                        </div>
                        <div className="footer__address">
                            <div>ADDRESS:</div>
                            <b>Selsdon House,
                                1 Upper Selsdon Rd,
                                S. Croydon, CR2 8DD</b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
}
export default Footer;

