import React, {useEffect, useState} from 'react';

import Slider from "../Slide";
import HomeBlock1 from "./home_block_1";
import HomeBlock2 from "./home_block_2";
import HomeBlock3 from "./home_block_3";
import HomeBlock4 from "./home_block_4";
import HomeBlock5 from "./home_block_5";
import SliderHome from "../Slide";
import Page from "../module/include/Page/Page";

class HomePage extends React.Component {

// const  HomePage = () => {
    state = {
        title: 'Welcome'
    };
    render() {
        return (<Page title={this.state.title}>
            <div>
            <SliderHome/>
            <HomeBlock1/>
            <HomeBlock2/>
            <HomeBlock3/>
            <HomeBlock4/>
            <HomeBlock5/>
        </div></Page>);

    }

}
export default HomePage;