import React from 'react';
import meet_teem from '../../access/img/M77A5361.jpeg';
import BookOnline from "../module/BookOnline/BookOnline";

const  HomeBlock4 = () => {
    return <section className="teamAndAppointment">
        <div className="container">
            <div className="row">
                <div className="col-lg-7">
                    <div className="team">
                        <h2 className="sectionTitle">Meet Our Team</h2>
                        <div className="team_img">
                            <img src={meet_teem} alt="" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <BookOnline />
                    {/*<div className="appointment">*/}
                    {/*    <h2 className="sectionTitle center">Make an Appointment</h2>*/}
                    {/*    <div className="book-online-content">*/}
                    {/*        <div className="back-fon"></div>*/}
                    {/*        <div className="book-text"><a href="https://pms.surreyphysio.co.uk/diary/online_booking">BOOK PHYSIO ONLINE</a></div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    </section>
}
export default HomeBlock4;