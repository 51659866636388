import React from 'react';
import Page from "../module/include/Page/Page";


class pilates extends React.Component {
    state = {
        title: 'Pilates'
    };
    render() {
        return (<Page title={this.state.title}>
            <section className="pilates">
                <div className="container">
                    <h2 className="sectionTitle">Pilates</h2>
                    <h3 className="sectionSubtitle">Why do pilates?</h3>
                    <p>
                        Kay TomlinsonThrough numerous studies it has been found that body movements are generated from
                        the
                        ‘core’ – a combination of deep spinal muscles attaching to the spine and abdominal wall creating
                        a ‘muscular
                        corsette’.
                    </p>
                    <p>
                        When we suffer back pain, the larger outer back muscles spasm, working harder while the deeper
                        stabilising
                        muscles work less. This leads to compression of joints!
                    </p>
                </div>
            </section>

            <section className="whatIsPilates">
                <div className="container">
                    <h2 className="sectionTitle">What is pilates?</h2>
                    <h4 className="sectionDesc">
                        The slow controlled flowing movement of Pilates primarily strengthens the ‘core’ muscles that
                        stabilize and support the spine, realign the body and correct postural problems.
                    </h4>
                    <h3 className="sectionSubtitle">Pilates in Croydon</h3>
                    <p>
                        The deep stabilising muscles activate most effectively in a neutral spinal position, important
                        for
                        protection of spinal discs, joints and ligaments. This rehabilitation exercise programme focuses
                        on
                        optimising these load-bearing relationships, activating the correct muscles and improving muscle
                        imbalances.
                    </p>
                </div>
            </section>
        </Page>);
    }
}
export default pilates;