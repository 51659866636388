import React from 'react';
import service_1 from '../../access/img/service_1.svg';
import service_2 from '../../access/img/service_2.svg';
import service_3 from '../../access/img/service_3.svg';
const  HomeBlock2 = () => {
    return <section className="service">
        <div className="container">
            <div className="desc">
                At Croydon Physiotherapy Clinic you can get the highest quality treatment from our amazing team of physiotherapists and osteopaths. We do see a lot of sports injuries and athletes but we also treat every-day joint and muscle pains, as well as help people to rehabilitate after surgery.
            </div>
            <h2 className="sectionTitle center">The service we offer is:</h2>
            <div className="service__cont">
                <div className="service__item">
                    <div className="service__item--icon">
                        <img src={service_1} alt="" />
                    </div>
                    <div className="service__item--name">
                        Affordable
                    </div>
                </div>
                <div className="service__item">
                    <div className="service__item--icon">
                        <img src={service_2} alt="" />
                    </div>
                    <div className="service__item--name">
                        Effective
                    </div>
                </div>
                <div className="service__item">
                    <div className="service__item--icon">
                        <img src={service_3} alt="" />
                    </div>
                    <div className="service__item--name">
                        Friendly
                    </div>
                </div>
            </div>
            <div className="desc right">
                That is why we are treating some of the UK's top sports people. We offer you a professional service which you can trust. And we are trusted by many clubs, teams and GPs in the Croydon area. We are very grateful to receive referrals from a large number of local GPs and Orthopaedic Consultants in Croydon.
            </div>
        </div>
    </section>
}
export default HomeBlock2;