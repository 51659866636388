import React from 'react';

import location from '../../access/img/location.svg';
import phone from '../../access/img/phone.svg';
import email from '../../access/img/email.svg';
import calendar from '../../access/img/calendar.svg';
import addimg from '../../access/img/addImg.svg';
import bookonline from "../module/BookOnline/BookOnline";
import BookOnline from "../module/BookOnline/BookOnline";
import Page from "../module/include/Page/Page";

class contact extends React.Component {
    state = {
        title: 'Contact Us'
    };

    render() {
        return (<Page title={this.state.title}>
            <section className="contacts">
                <div className="container">
                    <h2 className="sectionTitle">Contact Us</h2>
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="contacts__block">
                                {/*<iframe*/}
                                {/*    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d623.0705362206504!2d-0.06675392218369533!3d51.342722230551146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4875fe216e7d1637%3A0xd4dc442f904c4d61!2sUpper%20Selsdon%20Rd%2C%20South%20Croydon%20CR2%208DD%2C%20UK!5e0!3m2!1sen!2sua!4v1620323098021!5m2!1sen!2sua"*/}
                                {/*    style={{border: 0}} allowFullScreen="" loading="lazy"></iframe>*/}
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d623.0780183132686!2d-0.0653779710597595!3d51.34217185284718!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876007ca8f24ea7%3A0xdcaae830d071f7dc!2sCroydon%20Physiotherapy!5e0!3m2!1sen!2sua!4v1620639873644!5m2!1sen!2sua"
                                    style={{border: 0}} allowFullScreen="" loading="lazy"></iframe>
                                <div className="contacts__info">
                                    <div className="info__item">
                                        <div className="info__icon">
                                            <img src={location} alt=""/>
                                        </div>
                                        <div className="info__desc">
                                            <h4>ADDRESS</h4>
                                            <p>Selsdon House,</p>
                                            <p>1 Upper Selsdon Rd,</p>
                                            <p>S. Croydon, CR2 8DD</p>
                                        </div>
                                    </div>
                                    <div className="info__item">
                                        <div className="info__line">
                                            <div className="info__icon">
                                                <img src={phone} alt=""/>
                                            </div>
                                            <div className="info__desc">
                                                <h4>Tel:</h4>
                                                <p>0208 651 3315</p>
                                            </div>
                                        </div>
                                        <div className="info__line">
                                            <div className="info__icon">
                                                <img src={email} alt=""/>
                                            </div>
                                            <div className="info__desc">
                                                <h4>Email:</h4>
                                                <p>info@ croydonphysio.co.uk</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="info__item full">
                                        <div className="info__icon">
                                            <img src={calendar} alt=""/>
                                        </div>
                                        <div className="info__desc">
                                            <h4>OPENING HOURS</h4>
                                            <div className="half">
                                                <p>Monday 9.00am - 8.30pm</p>
                                                <p>Tuesday 9.00am - 6.00pm</p>
                                                <p>Wednesday 8.00am - 8.00pm</p>
                                            </div>
                                            <div className="half">
                                                <p>Thursday 8.00am - 8.30pm</p>
                                                <p>Friday 9.00am - 5.00pm</p>
                                                <p>Saturday 9.00am - 3.00pm</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <form action="" className="contactUs__form">
                                    <h2 className="form__title">Contact us</h2>
                                    <h3 className="form__subtitle">Fill out the form below to send us a message and we
                                        will get
                                        back to you ASAP.</h3>
                                    <div className="form-group">
                                        <input type="text" placeholder="Full name"/>
                                        <input type="tel" placeholder="Phone number"/>
                                    </div>
                                    <div className="form-group">
                                        <input type="email" placeholder="Email Address"/>
                                        <input type="text" placeholder="Subject"/>
                                    </div>
                                    <div className="form-group">
                                        <textarea name="msg" placeholder="Message"></textarea>
                                        <button className="btn addImgBtn" type="button">
                                            <img src={addimg} alt=""/>
                                        </button>
                                    </div>
                                    <button className="btn submitBtn">Submit request</button>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <BookOnline/>
                        </div>
                        <div className="col-lg-12">
                            <div className="directions">
                                <h2 className="direction__title">Directions</h2>
                                <p>We are located opposite the Selsdon Park Hotel, at 1 Upper Selsdon Rd, Croydon CR2
                                    8DD.</p>
                                <p>We have 10 onsite car parking spaces or free road parking.</p>
                                <p>The 412 Bus runs between Purley and West Croydon Bus Station and goes past the
                                    Fairfield
                                    Halls/Whitgift in Croydon. It stops right outside the clinic.</p>
                                <p>The 433 bus also stops very close to us at Sainsbury’s and you can go to Croydon or
                                    Addington.</p>
                                <p>Other busses are: 64 and 359.</p>
                                <p>We are not located close to a train station: Riddlesdown is 1.9 miles, South Croydon
                                    is 2.1
                                    miles, Purley Oaks is 2.7 miles and East Croydon is 3.1 miles. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Page>);
    }
}

export default contact;