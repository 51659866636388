import React from 'react';
import team from '../../access/img/team.png';
import addImg from '../../access/img/addImg.svg';
const  HomeBlock5 = () => {
    return <section className="reviews">
        <div className="container">
            <h2 className="sectionTitle">What our patients say</h2>
            <div className="row">
                <div className="col-md-6">
                    <div className="review">
                        <p>
                            Tim began treating me in 2004 after the birth of my twins. Since then he’s treated the whole
                            family. The twins saw Tim at each important developmental milestone to ensure that their spines
                            were perfectly aligned to give them the best start in life. My eldest son suffers from Irritable
                            Hip Syndrome and has had great relief following Tim’s treatment. The combination of Osteopathy
                            and Physiotherapy gives great versatility to Tim’s treatment and we are very happy with the care
                            we’ve had!”
                        </p>
                        <div className="author">Ros Wood</div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="review">
                        <p>
                            I had a knee operation to repair the anterior cruciate ligament and meniscus in July 2006. I started my rehab at croydon physio, Addington Palace. I am back to playing field hockey and skiing and all my sporting activities as I did before the accident.
                            What impressed me most about the clinic was not only their expert knowledge but also the personal care and attention to detail. I felt I was not just another patient, but an individual and I got tailor-made treatment. The staff constantly encouraged and motivated me, and nothing seemed to be too much trouble for them. I’m very thankful for all their help and support. I highly recommend the clinic for their professionalism and care."
                        </p>
                        <div className="author">A. Sharma, pharmacist, recommended by a local surgeon</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}
export default HomeBlock5;