import React from 'react';
import Page from "../module/include/Page/Page";

class news extends React.Component {
    state = {
        title: 'News us'
    };

// const news = () => {
    render() {
        return (<Page title={this.state.title}>
            <section className="news">
            <div className="container">
                <h2 className="sectionTitle">News</h2>
                <div className="news__item">
                    At Croydon Physiotherapy, we and our clients get up to some fun and
                    extra-curricular activities. If you have any news for us, email Tim.
                    <br/><br/>
                    11/11/16: Croydon Tram Incident: On Wednesday, a tram over-turned in Croydon injuring 50, and 7
                    members of our community tragically lost their lives. We have offered free treatment to anyone
                    who was on that tram, and requires rehabilitation following their injuries. We will initially
                    offer 6 treatments but may offer more if required. We see ourselves as very much part of the
                    Croydon community and wish to extend our help to anyone involved, and our sympathies to the
                    families. Please call us on 0208 651 3315 if you require treatment or help.
                    <br/><br/>
                    RIO OLYMPICS: Tim had an amazing time looking after the Olympic athletes as part of the
                    core medical team, in Athelete's Village, Rio. Tim said the experience was incredible
                    and he learned a lot. Pics to follow...
                    <br/><br/>
                    NOVEMBER 2015: IMPORTANT UPDATE: WE ARE MOVING to new premises - Selsdon House,
                    1 Upper Selsdon Rd, Croydon CR2 8DD. New telephone number is 0208 651 3315. We have parking on-site.
                    <br/><br/>
                    16-10-15 - Congratulations to Mercy Brown who won the European Junior Championships and Amber
                    Shepherd who finished 2nd in the Commonwealth Championships in Weightlifting.
                    <br/><br/>
                    1-02-15 - We are a London Marathon Injury Clinic for another year. If you are suffering a running
                    injury, make sure you come to see us. Richard and Amy are very good physios with experience with
                    runners and happy to see you.
                    <br/><br/>
                    4-11-14 - Tim has been featured in The Times, twice in the Daily Mail, and also in Woman and Home,
                    once in the Daily Mirror, and also the Sunday magazine.
                    <br/><br/>
                    1-10-14 - Well done Amber Shepherd who won the British U21 Championships
                    <br/><br/>
                    1-09-14 - Alex Al-Ameen, a British athlete and sprint-hurdler has returned to Croydon Physio clinic
                    for ongoing performance and rehab work. We looked after Alex a lot a few years ago, so its nice to
                    see him back. Alex got to the final of the Commonwealth Games, and finished runner-up in the African
                    Championships.
                    <br/><br/>
                    20-7-14 - OK we have 4 athletes competing at the Commonwealth Games in Glasgow, and two physios who
                    will be there too. Tim is Lead Physiotherapist based at Scotstoun Stadium, and Jack is working at
                    the Polyclinic in Athletes' Village. Good luck to all our patients competing!
                    <br/><br/>
                    1-10-13 - Tim is featured in Bodyfit magazine, Woman's Weekly and the Daily Mirror this month! Keep
                    an eye out for some of his articles.
                    <br/><br/>
                    8-9-12 - Mark Baylis achieves a new World Record running
                    from London (Marble Arch) to Dover, then swimming across the Channel, then cycling to Paris, in an
                    amazing 73 hours 39 minutes and 12 seconds! Amazing work!
                    TRULY awesome. (Nb. see news section on 11-9-07).
                    <br/><br/>
                    25-10-12 - Tim was speakingo on Russian World Service radio today talking about clothes that can
                    cause injury, including bras and shoes.
                    <br/><br/>
                    7-8-12 - GOOD LUCK TO JAMES ELLINGTON, Britain's top 200m sprinter, in the Olympics today!!! See our
                    Olympic Page for more info.
                    <br/><br/>
                    6-8-12 - GOOD LUCK TO LAWRENCE OKOYE and ABDUL BUHARI in the Olympics in Discus for GB!! See our
                    Olympic Page for more info.
                    <br/><br/>
                    21-7-12 - GOOD LUCK TO CASEY STONEY, GB Captain for Women's football, in the Olympics. See our
                    Olympic Page for more info.
                    <br/><br/>
                    20-7-12 - Three Croydon Physio physiotherapists are going to the Olympic Games! Tim is working in
                    the medical centre in Athletes' village, Olympic Park. Matt is lead physio for the GB men's Goalball
                    team and Emily Parry will be working at Paralympics with rowing.
                    <br/><br/>
                    9/2/12 - Well done to patients Halil Zorba, Jo Calvino, and Emily Godley at the weekend, all won the
                    English championships in Weightlifting, two got Olympic B qualifying, and two broke British records.
                    We helped both Emily and Jo prepare for this particular champs and continue to provide physio and
                    performance help for them.
                    <br/><br/>10/2/12 - Well done James Ellington, British International sprinter, on BBC Breakfast news
                    hoping to raise £30,000 sponsorship. James has been a patient at Croydon Physio for 3 years, and
                    recently mentioned us on TV. We are prepping him for the Olympics.
                    <br/><br/>10/1/12 - Tim was mentioned several times in the Daily Mail this week on 10/1/12 in an
                    article on joint pain.
                    <br/><br/>18-11-11 - Jaroslaw Olech and Jacek Wiak finish gold and 4th at the World Powerlifting
                    Champs. Jarik was also the top lifter at the champs on wilks points. Jacek had the biggest squat
                    422.5kg!!
                    <br/><br/>28-8-11 - Good luck to Abdul Buhari and James Ellington at the IAAF World Champs in Degu.
                    Good luck to James in the Diamond League events right afterwards.
                    <br/><br/>23-7-11 - David Bolarinwa gets bronze, silver and gold at U20 European Champs, gold in the
                    200m! Dave is a regular here in the clinic. Nice one Dave.
                    <br/><br/>09-07-11 - Amazing news! Abdul Buhari threw 65.44m and became the UK's third all time
                    longest thrower, and ranked no2 in the UK and world A standard qualifying. And then Lawrence Okoye
                    went and threw a 67.63m! That put him with the third longest throw in the world this year, and no1
                    in the UK, and I am sure has broken a number of other records. Both guys are still improving. Abdul
                    has been a patient since 2005, and Lawrence since Jan 2011.
                </div>
                <div className="news__item">
                    <img className="alignleft" src="https://www.croydonphysio.co.uk/images/news/1.JPG" alt="News #01"
                         border="0" style={{float: "left", margin: "7px 7px 7px"}}/>
                    25-2-11 - Croydon Physio were asked to look after the 10 dancers of the group Flawless, from
                    Britain's Got Talent. We sent Julie along to treat their aches and pains. Picture attached.
                    <br/><br/>20-2-11 - Tim was in Garmisch, Germany for the Ski World Championships 2011 with the Snow
                    Leopard, Kwame Nkrumah-Achaempong, Ghana's ski champ.
                    <br/><br/>19-1-11 - Well done Zoe Gillings who finished 7th in the World Snowboarding Championships.
                    We hope to work with you again Zoe.
                    <br/><br/>11-11-10 - Amazing news, and really well done to Terry Smith who won the World
                    Powerlifting Championships 2010. Terry was in for treatment just a few weeks before with a bad
                    muscle tear in his arm.
                    <br/><br/>3-10-10 - Good luck to our 6 athletes going to the Commonwealth Games 2010 in Delhi.
                    <br/><br/>23-7-10 - Amazing achievement for local hero Tom Godec who swam the English Channel in 12
                    hours 43 minutes. Tom saw several practitioners at Croydon Physio in the weeks leading up to his
                    epic swim, and made a full recovery just in time. Tim was on the boat during the Channel crossing to
                    give advice and support. Tom raised over £10,000 to build some classrooms for a school in Uganda...
                    amazing work!!
                    <br/><br/>4-6-10 - Tim was talking to Gabby Logan on Radio 5 Live about Rio Ferdinand's knee injury
                    which put him out of the World Cup.
                    <br/><br/>15-3-10 - Tim was talking on BBC Radio Surrey and Sussex to presenter Danny Pike about
                    David Beckham's achilles injury.
                    <br/><br/>27-2-10 - The Snow Leopard did a great job in his Olympic Slalom race, finishing 47th out
                    of 102. Tim was on the course and it was a really cool day.
                    <br/><br/>6-1-10 - Tim is currently with the Ghana Ski Team in Italy. I am organising all of Kwame
                    the Snow Leopard's physical training. Feb 2010 - Tim is now in Whistler with Kwame the Snow Leopard,
                    and the Ghana Ski Team preparing for the race on the 27th Feb
                    <br/><br/>13-11-09 - It gives me great pleasure to tell you all that Chris Martin and Mick Dawson
                    completed their row from Japan to San Francisco at 0826 (local time). Chris called me from his
                    satellite phone on board his rowing boat just after he crossed under the Golden Gate Bridge to tell
                    me his back had been great! It took the boys over 6 months to row unsupported on the 6000 mile
                    journey. Amazing. Phone call will be posted here soon.
                    <br/><br/>8-11-09 - Massive Congrats to Jarik and Jacek, 1st and 3rd at the Powerlifting World
                    Champs in India.
                    <br/><br/>3-10-09 - Tim was invited to lecture at The Back Show, at Earl's Court. He spoke on the
                    latest rehab techniques for back pain to osteopaths, physios and chiropractors. He also did a
                    seminar to members of the public on how to exercise their back, and also a Great Debate representing
                    osteopaths.
                    <br/><br/>9-09 - Tim spent 40 mins in the studio with Chris Evans, Jonny Saunders and Sally Traffic
                    yesterday at the BBC. It was quite a nerve racking experience but it was great fun. Chris is a
                    massive supporter of osteopaths and regularly gets treatment, and was certainly enjoying Tim's
                    treatment and was very complimentary on air afterwards. Many thanks to all the texts from osteopaths
                    and patients who heard the show. You can listen here Listen Here (sorry currently removed will be
                    back online soon).
                    <br/>Tim was also on Radio 4 Ireland at lunchtime talking about more cracking.
                    <br/><br/>09 - Tim was on BBC Radio 2 Drivetime with Chris Evans talking about cracking joints, and
                    what causes the sound of the crack. Chris invited Tim onto the show tomorrow. Listen Here
                    <br/><br/>19-8-09 - Best of luck to patient Casey Stoney for the EURO 2009 (Women's European
                    Football Champs) in Finland. Latest News: England got to the FINAL! Well done girls.
                    <br/><br/>01-8-09 - Tim was on Radio 5 Live today talking about Freddie Flintoff's right knee
                    injury, and how he was managing his rehab with cortisone injections. Listen Here
                    <br/><br/>29-7-09 Tim got back from The World Games 2009, Kaohsiung, Taiwan as physio for the Poland
                    Powerlifting Team. We did very well - 1 Gold (Jarik), 1 Silver (Jacek) and 1 bronze. And three world
                    records. Check out the video from The World Games Closing Ceremony
                    <br/><br/>27-7-09 - Sarah completed the London-Paris bike ride and finished 9th overall, and 1st
                    woman.
                    <br/><br/>19-7-09 - Tim was the Boffin on "Boffin's Batton!" on Radio 3 Counties on Friday morning.
                    Yon can Listen Here
                    <br/><br/>12-7-09 - Well done to all our athletes at the UK Championships and World Trials. Two of
                    our former patients won, and we had six finalists.
                    <br/><br/>30-6-09 - Tim was live with Richard Bacon in the Radio 5 Live studio's at BBC Television
                    Central in White City talking about Andy Murray's preparation for his quarter-final match against
                    Juan Carlos Ferrera.
                    <br/><br/>23-6-09 - Tim was on BBC World Service radio talking about Raffa Nadal's knee injury and
                    shock withdrawal from Wimbledon. Listen Here
                    <br/><br/>12-5-09 - Tom Richardson, currently at College in the States, was individually tied 1st
                    overall in the California State Champsionships and his team were crowned State Champions as well.
                    Tom you are doing really great.
                    <br/><br/>8-5-09 - Gold and Silver at European Powerlifting Champs - well done Jacek and Jarik. Now
                    we have to get Jacek fit for the World Games.
                    <br/><br/>25-4-09 - We are opening a new physiotherapy clinic in Wallington. We have a great new
                    team starting as well, some of you will have met them already as they are undergoing training at
                    Croydon Physio.
                    <br/><br/>25-3-09 - Tim chatted to Colin on the Simon Mayo show on Radio 5 Live about England
                    Footballer Ledley King and his knee problems. Listen Here
                    <br/><br/>22-2-09 - Tim chatted to Tony Livesey again on Radio 5 Live about Andrew Flintoff's right
                    hip injury in the 3rd test in Antigua. Listen Here
                    <br/><br/>23-12-08 - Tim chatted to Joel on BBC Radio Ulster about Nintendo Wii injuries. Listen
                    Here
                    <br/><br/>20-12-08 - Tim was on the Stephen Nolan show chatting to Tony Livesey on Radio 5 Live
                    about Nintendo Wii injuries, and "Wii Knee". Listen Here
                    <br/><br/>18-12-08 - Tim was talking to Peter Allen on 5 Live Drive (Radio 5 Live) about Eduardo's
                    return to football following that horrible ankle injury. Listen Here
                    <br/><br/>9-11-08 - WOWZERS! GOLD AND SILVER AT THE WORLD POWERLIFTING CHAMPS!!! Jaroslaw (Jarik)
                    Olech and Jacek Wiak have been seeing us twice weekly for the last few months to get themselves in
                    peak preparation for the Worlds! And it worked. Jarik got Gold and Jacek got Silver.
                    <br/><br/>7-11-08 - Tim was on Radio 5 Live again today chatting on the Richard Bacon Show for a 1
                    hour debate on "Do we push our athletes too hard". It was a fantastic debate. You can Listen Here if
                    you missed it.
                    <br/><br/>18-7-08 - Tim was on Radio 5 Live yesterday morning talking to Mark Pougatch about Padraig
                    Harrington's wrist injury before he teed off to defend his Golf Open Title... Listen here
                    <br/><br/>10-5-08 - Well done to Jacek Wiak who finished 3rd in the 2008 European Powerlifting
                    Champtionships in the Czech Republic. Jacek suffered a pectoral strain just 3 weeks before the
                    champs which almost certainly lost him the silver. Still a great result though.
                    <br/><br/>5-5-08 - Amir Williamson wins the British University Championships this weekend with a
                    massive PB launching him to 4th in the UK. Joe Kitson has been working with Amir and his coach for
                    the last few weeks to see if improving his biomechanics will help his throw.
                    <br/><br/>28-3-08 - Tim would like to wish his 5 Professional Tour Golfers - Danny W, Dave, Eren,
                    Lewis and Phil the best of luck for what will hopefully be a very good season.
                    <br/><br/>25-2-08 - Tim was on Radio 5 Live this morning talking to Nicky Campbell about Eduardo da
                    Silva's horrific broken leg two days ago in the Arsenal v Birmingham City match. Listen Here
                    <br/><br/>18-2-08 - Another very good achievement by Jo Calvino who qualified for the European
                    Weight Lifting Champs 2008 with two more British Records this weekend. We look after all of Jo's
                    physio needs.
                    <br/><br/>17-2-08 - Congratulations to Laura Langowski who finished 2nd in the National Indoor
                    Championships at 400m. Laura has been seeing our physios over the last few weeks to keep herself at
                    optimum fitness.
                    <br/><br/>
                </div>
                <div className="news__item">
                    <img className="alignleft" src='https://www.croydonphysio.co.uk/images/news/2.JPG' alt="News #02"
                         border="0"/>31-1-08 - We have been invited to be an injury clinic partner for the London
                    Marathon: "The The Flora London Marathon Injury Clinic only invites clinics who are qualified up to
                    the highest level and has great experience in injury prevention, injury rehabilitation and
                    performance assistance specific to endurance running".

                </div>
                <div className="news__item">
                    30-1-08 - Congratulations to some of our athletes this weekend - Laura Langowski won the 400m in
                    Bratislava for Team GB. Danny Davis finished 2nd in the Irish Nationals 110m hurdles.
                    <br/><br/>28-11-07 - Jo Calvino today achieved a 3rd in the Commonwealth Championships and 4th in
                    the World Cup in Samoa. We saw her just over a week ago, and she finds regular treatment keeps her
                    body in check.
                    <br/><br/>23-11-07 - Good luck to Justin Ellis and his team who are going to the Canary Islands
                    today in preparation for the 2007 Atlantic Rowing Race. Justin saw us 11 months ago after having
                    shoulder surgery and the rehab was very successful allowing him to regain full strength in
                    preparation for the 3000 mile row.
                    <br/><br/>11-11-07 - One of our favourite clients, Jon Will, was today crowned European Kick-boxing
                    champion for a second time. We were pleased this year he didn't sustain any fractures.
                    <br/><br/>26-10-07 - Thanks Harry Cowap, ex-profesional boxer and client who took Tim to a boxing
                    night with Barry McGuigan. A most excellent evening!
                    <br/><br/>24-9-07 - Tim was recently invited to play golf with Monty for a third time, at Hanbury
                    Manor - a fantastic venue, and great day away from the clinic.
                    <br/><br/>11-9-07 - A brilliant achievement of Mark Bayliss who swam across the English Channel in
                    the early hours of the morning. He completed the swim in 11.5 hours. Mark has been getting regular
                    treatment over the last 8 months for a persistent shoulder problem. Great result Mark.
                    <br/><br/>28-7-07 - Congrats to Abdul Buhari who finished second in the AAA National Championships
                    and World Qualifiers with a PB in the discus. Abdul has had a tough season with a fractured foot,
                    and then shin splints, and was still only semi-fit for his throw, but getting a lot better.
                    <br/><br/>4-7-07 - We are now BUPA registered. This means we are covered by all major insurance
                    companies.
                    <br/><br/>8-5-07 - Well done Captain Casey and the Charlton girls who got to the FA Cup final. Also
                    congratulations to Lombard Phoenix who did the league and cup double this weekend.
                    <br/><br/>18-3-07 - Sarah now a Physiotherapy Master <br/>Congratulations to Sarah who qualified in
                    her MSc in Physiotherapy this weekend. Good work! <br/> <br/>10-3-07 - Boxing at the Royal National
                    Hotel
                    <br/>Just a quick thanks to client, and ex-professional boxer Harry Cowap for the special VIP
                    invitation to his boxing event with Nigel Benn. Great evening.
                    <br/> <br/>16-12-06 - The end of 2006 report <br/>Its been a brilliant year, and I would personally
                    like to thank everyone who has supported and recommended us and the clinic here at Addington Palace.
                    We think we have done a good job, and from the feedback we have received, you think we have as well.
                    So if you need an injury treated next year, sports or general injury, just give us a call.
                    <br/><br/>10-09-06 - We say goodbye to two of our favourite clients: Geraldine Pillay and Kareem
                    Streete-Thompson <br/>Geraldine Pillay, the 2006 Commonwealth Silver and Bronze medalist
                    (100m,200m), left this week to go back to South Africa. LASER and some soft tissue helped a nasty
                    hamstring pull which Geraldine couldn't shake off. Kareem Streete-Thompson, the former World no2
                    Long Jumper and sub 10 second 100m sprinter said goodbye as he headed back at the end of the summer
                    to George Town, Cayman Islands. We have an open door for you guys next year should you need a physio
                    team.
                    <br/> <br/> <br/>13-08-06 - Joice Maduaka takes Silver in European Championships <br/>How far have
                    we seen Joice progress this year? When we took her on she was running 11.60 and is now consistently
                    running 11.2 I am very proud of the success this devoted athlete has achieved well done.
                    <br/> <br/>22-07-06 - Jo Calvino wins British Weightlifting Championships <br/>A great girl and a
                    great lifter. Good work. Enjoy your holiday see you when you get back.
                    <br/> <br/>16-07-06 - 2 Golds at Athletics National Championships <br/>Joice became Britains most
                    successful athlete ever winning her 17th Gold medal and doing the 100m/200m double. See you next
                    week!
                    <br/> <br/>09-07-06 - Danny Davis Takes Gold at English Schools Championships <br/>Danny has been
                    working to get over injury for around 6 months now, and did particulary well to take Gold in the
                    110m hurdles at Gateshead. That makes him national champion for a second year running.
                    <br/> <br/>18-06-06 - Joice Maduaka does the 100m/200m double at Crystal Palace <br/>Tim and Kemila
                    were at Crystal Palace this weekend to watch our athletes in action. All did well. But probably the
                    highlight was watching Joice Maduaka run 11.33s in the 100m to run the fastest British time this
                    year. And then doing the same thing in the 200m running 23.25s again the fastest time by a British
                    Athlete this year. Joice is a regular client of ours and we have been correcting imbalances in her
                    body and strengthening her weak areas and it seems to be paying off. Watch this space.
                    <br/> <br/>17-03-06 - Jo Calvino finishes 4th at Commonwealth Games <br/>Congratulations to Jo
                    Calvino who finished 4th in Melbourne. Always the worst position to finish in, but we still think it
                    was a fantastic achievement. <br/> <br/>10-03-06 - Good Luck to our athletes at The 2006
                    Commonwealth Games, Melbourne, March 15th <br/>Good luck to John (Cam) Osman and Jo Calvino in
                    Melbourne. Both regular clients here at Addington Palace, we have our fingers crossed for you
                    guys. <br/> <br/>21-02-06 - Well done Shani Reinford <br/>Shani Reinford, previously ranked #1 in
                    u17 womens high jump, has achieved a personal best of 1.78cm in the womens u20 indoor meet on
                    saturday. Shani recovered well from a badly strained hip flexor muscle, with treatment at the sports
                    injury clinic, now just a few cm off qualifying for the world indoor junior championships in a few
                    weeks time. Good luck. Kemila. <br/> <br/>7-02-06 - Chris Martin Crosses the Atlantic!! <br/>After
                    68 days, 15 hours and 19 minutes, Chris Martin achieved the sensational feat of rowing solo across
                    the Atlantic, and finishing first solo crosser. Chris had his thrills and spills, including surfing
                    at 16 knots down a wave (yes in a rowing boat!), capsizing, losing his water filterer, rowing next
                    to sharks, and getting a bad back. But help was at hand as soon as Chris got back to Blighty and he
                    knew there was only one place to come - into the clinic to get it sorted! A truly remarkable
                    achievement, and I strongly urge you to check out his website (see news lower down) and read his
                    weblog which is very good indeed. Photos to follow. <br/> <br/>1-1-06 - New for 2006 - More Rehab
                    groups! <br/>Here at the sports injury clinic we strongly believe in rehabilitation for the
                    prevention of any re-occuring injuries. Adding to the success of our knee rehabilitation clsasses
                    which take place on Tuesdays 7-8.30 and fridays 7-8.30, are now introducing core/lower back
                    rehabilitation classses on a Tuesday 8.30-10 and shoulder rehabilitation on friday 5.30-7/ 8.30-10
                    anyone who is interested please contact the clinic on 02086625059 and ask for Sarah. <br/> <br/>12-12-05
                    - Tim Lectures for the PGA (Professional Golfer's Association) <br/>Tim recently held another very
                    successful lecture for the PGA teaching golf biomechanics to PGA pro's. For more information on up
                    and coming golf lectures contact Tim. <br/> <br/>27-11-05 - Jon wins European Kickboxing
                    Championships <br/>An awesome achievement for Jon Will to win the 2005 World United Martial Arts
                    Federation European Championships in the 65kg class. It looks like the swiss ball training came in
                    useful!! (see photos page). I've diagnosed Jon with a cracked rib so its 6 weeks rest now. <br/>
                    <br/>26-11-05 - Tim invited to England v Samoa with England legend <br/>Thanks to former England
                    Rugby Coach and British Lions Coach Dick Best for providing tickets to Twickenham to see England v
                    Samoa. It was a fantastic day, courtesy of Rita, and Dick you cooked an awesome curry today! See
                    photos. <br/> <br/>1-11-05 - Tim Assists with England U21 Golfers <br/>Tim had a brilliant time with
                    some of the hottest young talent in the UK this week at Woodhall Spa for the England U21 training
                    camp. Thanks to Lynn Booth for making it happen. Check out http://timgolf.com. <br/> <br/>20-10-05 -
                    Tim and Colin Montgomerie <br/>Tim had the most fantastic day courtesy of Rita Livesey of ECCO shoes
                    where he had the pleasure of assisting Colin Montgomerie around 18 holes of The Grove, Herts. Colin
                    was in great form (probably because Golf Nurse was also there) and everyone had a brilliant day! See
                    photos. <br/> <br/>15-10-05 - Jo finishes 4th in Commonwealth Championships <br/>Jo Calvino,
                    Britains no1 female power lifter, and British Champion more times than i can count, finished a very
                    respectable 4th in Perth last weekend. Jo consulted The Sports Injury Clinic with an acute lower
                    back injury which she needed fixing fast. And fix-it we did. 7 days later she was as fit as a fiddle
                    and as happy as larry. We are now looking after Jo in preparation for The Commonwealth Games in
                    Perth in March 06. <br/> <br/>15-10-05 - Anna wins 4th World Title <br/>Well done Anna for winning
                    your 4th World Championships in Oz recently. What a fantastic result! What more can I say. <br/>
                    <br/>23-07-05 - Anna Hemmings wins European Championships in Czechslovakia <br/>Anna has won the
                    Europeans to add to her National titles this year in the K1 Marathon class. The Sports Injury Clinic
                    has been helping Anna rid of a chronic hamstring injury, and also helping GB team-mate Zara Dale
                    back from injury with biceps tendonitis. <br/> <br/>08-7-05 - Abdul Bulhari sets sights on AAA
                    Championship <br/>After suffering a torn adductor muscle for four months, Abdul Buhari, GB Athletics
                    team, and one of the UK's best discus throwers decided to consult The Sports Injury Clinic for help.
                    Two treatments later, he is in a much improved state for this weekend's televised AAA Championship
                    and World and European Championship Qualifier. We will keep you posted on his performance. Latest
                    News: Abdul finished an impressive 4th (despite being injured) missing out on 3rd place by only 5cm.
                    Great Result! <br/> <br/>07-7-05 - Chris Martin requests The Sports Injury Clinic to assist in
                    Trans-Atlantic Crossing <br/>Chris Martin, former World Championship Bronze medalist rower, has
                    requested the Sports Injury Clinic to assist in the conditioning and rehabilitation in preparation
                    for a truly epic challenge... to solely row across the Atlantic Ocean (3000 miles) in 42 days.
                    Please sponsor Chris on http://transatlanticforce.co.uk <br/> <br/>05-7-05 - Tim Receives Chartered
                    Recognition <br/>Tim from the Sports Injury Clinic is delighted to have received notification from
                    the Chartered Society of Physiotherapy that he has been approved as a Chartered Physiotherapist as
                    well as being State Registered. <br/> <br/>03-7-05 - Anna Hemmings wins four National Kayaking
                    Championship Races <br/>Congratulations to Anna Hemmings, former Olympic semi-finalist, three times
                    world champion, and client of the Sports Injury Clinic, who on Sunday won three further National
                    Championship races over 200m, 500m and 1000m. For more information, goto
                    http://annahemmings.com <br/> <br/>17-6-05 - The Sports Injury Clinic providing treatment to Pindar
                    sports stars <br/>The Sports Injury Clinic is in talks with Andrew Pindar and Robin Gray of Pindar
                    Plc to provide top British sailors and canoists with treatment, rehab and conditioning programmes.
                    For more information, goto http://pindar.com <br/> <br/>09-5-05 - Mayor of Croydon consults for a
                    postural assessment <br/>The Mayor of Croydon was treated to a postural assessment and treatment
                    recently, and Tim was pictured in the local press with her.
                    <br/><br/>02-5-05 - Casey Stoney England International and Charlton Captain wins FA Cup
                    <br/>Casey Stoney, the Charlton Athletic Women's Captain has lead her team to victory in the FA Cup.
                    Despite a quad-strain 5 days before the final, two treatments in quick succession sorted it and she
                    was 100% fit for the game. Tim was pitchside to witness the action, and celebrated in the players
                    bar afterwards!
                </div>
            </div>
            </section>
        </Page>);
    }
}
export default news;