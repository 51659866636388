import React from 'react';

const  HomeBlock3 = () => {
    return <section className="weUse">
        <div className="container">
            <p>
                We use a range of techniques, including LASER and ultrasound, manipulation, massage, acupuncture and
                rehabilitation.
            </p>
            <p>
                Selsdon House is located at 1 Upper Selsdon Rd, and is a fantastic physio and osteopathy clinic. There
                is free parking on-site and a newly refurbished clinic.
            </p>
            <p>
                Our clinic is registered with PhysioFirst which is a Private Practice Physiotherapy Group, and one which all
                established physio clinics join. They provide high standards which all member clinics adhere to.
            </p>
            <p>
                The Croydon Physiotherapy and Osteopathy Clinic is within easy reach of East Croydon, Central Croydon, South
                Croydon, Purley, Selsdon, Sanderstead, Coulsdon, Addington, Wallington, Warlingham, Beckenham, Hayes,
                Shirley, and West Wickham. If you are looking for top quality physiotherapy in Croydon and surrounding area,
                you have come to the right place.
            </p>
        </div>
    </section>
}
export default HomeBlock3;