import React from 'react';

import payCard_1 from '../../access/img/payCard_1.svg';
import payCard_2 from '../../access/img/payCard_2.svg';
import payCard_3 from '../../access/img/payCard_3.svg';
import {NavLink} from "react-router-dom";
import Dropdown from '../module/Dropdown/Choose/dropdown';
import Page from "../module/include/Page/Page";

class cost extends React.Component {
    state = {
        title: 'Cost',
        payCustom: '45',
    };

    render() {
        const chooses1 = [{'id': '1', 'title': 'Physiotherapy 45.00 GBR', 'value': 'Physiotherapy'},{'id': '1', 'title': 'Osteopathy 45.00 GBR', 'value': 'Osteopathy'}];
        const chooses2 = [{'id': '1', 'value': 'GBR'}];
        return (
            <Page title={this.state.title}>
                <section className="cost">
                    <div className="container">
                        <h2 className="sectionTitle">Cost</h2>
                        <h3 className="sectionSubtitle">
                            The cost of treatment is £45 for the consultation, and £45 for subsequent treatments. We
                            charge around £45 for insurance companies although that may vary slightly for each insurance
                            company. Treatment usually lasts up to 30 minutes. Should you require an hour, our charge is
                            £70. Some treatments with Tim are 20 minutes (Saturdays and late evenings) and the cost of
                            this is £35. Home visits are £80 if within 15 minutes, and £90 if within 30 minutes of our
                            location. Each home visit lasts 30-40 minutes.
                        </h3>
                        <div className="payment">
                            <div className="payWay">
                                <div className="payWay__title">Choose treatment</div>
                                {/*<select name="treatment" id="treatment">*/}
                                {/*    <option value="0" hidden></option>*/}
                                {/*    <option value="1">Physiotherapy 45.00 GBR</option>*/}
                                {/*</select>*/}

                                {/*<div className="nice-select" tabIndex="0"><span className="current"></span>*/}
                                {/*    <ul className="list">*/}
                                {/*        <li data-value="0" className="option"></li>*/}
                                {/*        <li data-value="1" className="option selected">Physiotherapy 45.00 GBR</li>*/}
                                {/*    </ul>*/}
                                {/*</div>*/}

                                
				{/*
				<Dropdown choose={chooses1} name={'treatment'}/>
				<button className="btn payment__btn">Pay now</button>
								<div className="payCards">
								    <NavLink to="/">
									<img src={payCard_1} alt=""/>
								    </NavLink>
								    <NavLink to="/">
									<img src={payCard_2} alt=""/>
								    </NavLink>
								    <NavLink to="/">
									<img src={payCard_3} alt=""/>
								    </NavLink>
								</div>
				*/}
                                
                                
				<form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
				    <input type="hidden" name="cmd" value="_xclick" />
				    <input type="hidden" name="business" value="tim@surreyphysio.co.uk" />
				    <input type="hidden" name="lc" value="GB" />
				    <input type="hidden" name="item_name" value="Appointment" />
				    <input type="hidden" name="button_subtype" value="services" />
				    <input type="hidden" name="no_note" value="0" />
				    <input type="hidden" name="currency_code" value="GBP" />
				    <input type="hidden" name="bn" value="PP-BuyNowBF:btn_paynowCC_LG.gif:NonHostedGuest" />

				    <input type="hidden" name="on0" value="Type" />
				    <Dropdown choose={chooses1} name={'treatment'}/>
				    {/*<select name="os0">
					<option value="Physiotherapy">Physiotherapy £45.00 GBP</option>
					<option value="Osteopathy">Osteopathy £45.00 GBP</option>
				    </select>*/}
				    <input type="hidden" name="currency_code" value="GBP" />
				    <input type="hidden" name="option_select0" value="Physiotherapy" />
				    <input type="hidden" name="option_amount0" value="45.00" />
				    <input type="hidden" name="option_select1" value="Osteopathy" />
				    <input type="hidden" name="option_amount1" value="45.00" />
				    <input type="hidden" name="option_index" value="0" />

				    <div class="clearfix"></div>

				    <input  className="btn payment__btn" value="Pay now" type="submit" src="https://www.paypalobjects.com/en_GB/i/btn/btn_paynowCC_LG.gif" border="0"
					   name="submit" alt="PayPal – The safer, easier way to pay online." />
					<div className="payCards">
		                            <NavLink to="/">
		                                <img src={payCard_1} alt=""/>
		                            </NavLink>
		                            <NavLink to="/">
		                                <img src={payCard_2} alt=""/>
		                            </NavLink>
		                            <NavLink to="/">
		                                <img src={payCard_3} alt=""/>
		                            </NavLink>
		                        </div>
				</form>
                                
                            </div>
                            <div className="payWay">
                                <div className="payWay__title">Or pay custom amount:</div>
                                {/*<select name="customAmount" id="customAmount">*/}
                                {/*    <option value="0" hidden></option>*/}
                                {/*    <option value="1">GBR</option>*/}
                                {/*</select>*/}
                                {/*<div className="nice-select" tabIndex="0"><span className="current"></span>*/}
                                {/*    <ul className="list">*/}
                                {/*        <li data-value="0" className="option selected"></li>*/}
                                {/*        <li data-value="1" className="option">GBR</li>*/}
                                {/*    </ul>*/}
                                {/*</div>*/}
                                
                                {/*<Dropdown choose={chooses2} name={'customAmount'}/>

                                <button className="btn payment__btn">Pay now</button>
                                <div className="payCards">
                                    <NavLink to="/">
                                        <img src={payCard_1} alt=""/>
                                    </NavLink>
                                    <NavLink to="/">
                                        <img src={payCard_2} alt=""/>
                                    </NavLink>
                                    <NavLink to="/">
                                        <img src={payCard_3} alt=""/>
                                    </NavLink>
                                </div>
                                */}
                                
                                
                                
                                <form action="https://www.paypal.com/cgi-bin/webscr" method="post">
				    <input type="hidden" name="cmd" value="_xclick" />
				    <input type="hidden" name="business" value="tim@surreyphysio.co.uk"/>
				    <input type="hidden" name="lc" value="GBP"/>
				    <input type="hidden" name="item_name" value="Custom amount"/>
				    <input type="hidden" name="button_subtype" value="services"/>
				    <input type="hidden" name="no_note" value="0"/>
				    <input type="hidden" name="cn" value=""/>
				    <input type="hidden" name="no_shipping" value="2"/>
				    <input className="nice-select" name="amount" value={this.state.payCustom} onChange={(e)=>{this.setState({payCustom:e.target.value})}} /> 
				    {/*<span style="display: inline">GBP</span>*/}
				    <input type="hidden" name="currency_code" value="GBP"/>
				    <input type="hidden" name="bn" value="PP-BuyNowBF:btn_buynowCC_LG.gif:NonHosted"/>

				    <div class="clearfix"></div>

				    <input type="submit" className="btn payment__btn" src="https://www.paypalobjects.com/en_GB/i/btn/btn_paynowCC_LG.gif" border="0"
				           name="submit" alt="PayPal – The safer, easier way to pay online."/>
				    <div className="payCards">
		                            <NavLink to="/">
		                                <img src={payCard_1} alt=""/>
		                            </NavLink>
		                            <NavLink to="/">
		                                <img src={payCard_2} alt=""/>
		                            </NavLink>
		                            <NavLink to="/">
		                                <img src={payCard_3} alt=""/>
		                            </NavLink>
		                        </div>
				</form>
                                
                            </div>
                            <div className="cheque">
                                <h3 className="cheque__title">Insurance codes:</h3>
                                <p>BUPA Physio: 80009032</p>
                                <p>BUPA Osteopathy: 30032104</p>
                                <p>AXA/PPP Physio: ZZ01300</p>
                                <p>AXA/PPP Osteopathy: TA00652</p>
                                <p>Aviva: 600033966</p>
                                <p>WPA Physio: 3926528</p>
                                <p>WPA Osteopathy: 920272028</p>
                            </div>
                        </div>
                        <div className="paragraph color__grey">PLEASE REMEMBER YOUR INSURANCE IS YOUR RESPONSIBILITY. If
                            the
                            insurance company does not pay, you will be responsible for any excess.
                        </div>
                        <div className="paragraph color__red">PLEASE NOTE AT CROYDON PHYSIOTHERAPY WE ARE VERY BUSY AND
                            WE DO
                            REQUEST 24 HOURS' NOTICE IF YOU WISH TO CANCEL, OR WE CHARGE £25.00 FOR MISSED APPOINTMENTS.
                        </div>
                        <p>
                            We do provide Home Visits - the cost varies depending on your location and the time it takes
                            us to get
                            to you, but starts at £80 for local call-outs.
                        </p>
                        <p>
                            We are covered by all major insurance companies, including...
                        </p>
                        <p>
                            BUPA, AXA PPP, Aviva, Simply Health, Royal and Sun Alliance, Vitality WPA, Cigna UK,
                            Groupama, and Benendon.
                        </p>
                    </div>
                </section>
            </Page>);
    }
}

export default cost;
