import React from 'react';

import person_1 from '../../access/img/person_1.png';

import person_4 from '../../access/img/person_4.png';

import person_8 from '../../access/img/M77A8823.jpeg';
import person_9 from '../../access/img/M77A6248.jpeg';
import person_10 from '../../access/img/M77A6243.jpeg';
import person_11 from '../../access/img/M77A2383.jpeg';
import Page from "../module/include/Page/Page";

class meetTeem extends React.Component {
    state = {
        title: 'Meet the Team'
    };
// const  meetTeem = () => {
    render() {
        return (<Page title={this.state.title}>
            <section className="meetTheTeam">
                <div className="container">
                    <h2 className="sectionTitle">Meet the Team</h2>
                    <h3 className="sectionSubtitle">Basically, you're in safe hands from a well-trained team.</h3>
                    <div className="aboutTeam">
                        <div className="aboutTeam__image">
                            <img src={person_8} alt=""/>
                        </div>
                        <div className="aboutTeam__desc">
                            {/*Will is an experienced Chartered Physiotherapist who is also the Lead Physiotherapist for our Wandsworth and Lambeth Team, and Lead for Whitgift School.*/}
                            <p>Will is a highly specialist musculoskeletal and sports physiotherapist, currently working
                                as the Clinical Team Lead in South London for Surrey Physio, running the First Contact
                                Physiotherapy service and managing a large team. He regularly provides training/lectures
                                for GP's and is actively involved in the management/enhancement of the MSK systems in
                                South London. He is also qualified in Soft Tissue and Joint Injections, regularly
                                completing these both for the FCP service and privately.</p>

                            <p>Will also works as the Head Physiotherapist at Whitgift School where he works alongside
                                the medical team providing pitch-side physiotherapy and rehabilitation for some of the
                                country's top aspiring young athletes.</p>

                            <p>Will has previously worked with Olympians, Paralympians, a Premier League football club,
                                and professional hockey and cricket players, he has also worked at International
                                Athletic events. Will's strong belief is that personalised gym and exercised-based
                                physiotherapy is key to a patient's recovery and long-term management of injuries,
                                whether these be chronic conditions, acute sports injuries or simply injury prevention.
                                Will's combines this approach with acupuncture, injections, shockwave and sports taping
                                to aid rehabilitation. Will has also completed specialist training on Concussion
                                rehabilitation, as well as Sports and Exercise Nutrition to help patients/athletes
                                unlock another level of performance.</p>

                            <p>Will currently has very limited slots on a Monday afternoon/evening at Selsdon, if you
                                would like to book in with him please have a chat with reception and they can put you in
                                contact with him.</p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="aboutPerson">
                <div className="container">

                    {/*<div className="person">*/}
                    {/*    <div className="person__desc">*/}
                    {/*        <div className="person__name">Will</div>*/}
                    {/*        <div className="person__excerpt">*/}
                    {/*            <p>Will is an experienced Chartered Physiotherapist who is also the Lead Physiotherapist for our Wandsworth and Lambeth Team, and Lead for Whitgift School.</p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="person__img">*/}
                    {/*        <img src={person_8} alt="" />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="person">
                        <div className="person__desc">
                            <div className="person__name">Tim Allardyce</div>
                            <div className="person__excerpt">
                                <p>
                                    Tim has spent seven years training as an osteopath and physiotherapist and in
                                    February 2005 he set up Croydon Physiotherapy Clinic. He specialises in treating
                                    elite athletes and currently his clientele have included Olympians, World elite
                                    athletes, and everyday people too. Tim is the proud Clinical Director of Surrey
                                    Physio. Unfortunately due to time constraints, Tim has not taken on new patients
                                    himself since 2015 but can recommend his amazing team.
                                </p>
                            </div>
                        </div>
                        <div className="person__img bigOne">
                            <img src={person_1} alt=""/>
                        </div>
                    </div>
                    <div className="person">
                        <div className="person__desc">
                            <div className="person__name">Niamh</div>
                            <div className="person__excerpt">
                                <p>Niamh graduated from the British school of Osteopathy after successfully completing a
                                    4-year degree course. She is an excellent structural osteopath who has experience
                                    treating a range of people including expectant mothers, sports people and children.
                                    Niamh has a special interest in women's health and fitness related injuries, and has
                                    a background in competitive sports including swimming and tennis. She is also a
                                    qualified Pilates instructor. In 2018 we were extremely proud that Niamh was awarded
                                    "Osteopath of the Year" in a National award ceremony. </p>
                            </div>
                        </div>
                        <div className="person__img">
                            <img src={person_9} alt=""/>
                        </div>
                    </div>
                    <div className="person">
                        <div className="person__desc">
                            <div className="person__name">Peter</div>
                            <div className="person__excerpt">
                                <p>
                                    Peter is an established member of the Croydon Physio team, and has made a huge
                                    impression on so many patients over the last few years. He is a chartered
                                    physiotherapist who graduated in 2009. He worked for over 3 years in a highly
                                    specialist military condition centre treating back pains. Most of his patients were
                                    elite Air Force. Treating back pain has become his main interest and now he uses
                                    numerous ways like massage, exercise and physiotherapy to help patients in recovery.
                                </p>
                                <p>
                                    Peter has also been a qualified massage therapist since 2006 specialising in many
                                    types of massage treatments: segmentary, deep tissue, sports, Swedish, reflexology,
                                    lymphatic drainage, acupressure, remedial. He has previously worked with a National
                                    sailing team as their physio.
                                </p>
                                <p>
                                    As a physiotherapist Peter has experience in using electrotherapy equipment, light
                                    therapy, ultrasound, hydrotherapy and manual techniques. He also enjoys developing
                                    and reviewing treatment programmes, tailored to individual patient’s needs.
                                </p>
                            </div>
                        </div>
                        <div className="person__img">
                            <img src={person_10} alt=""/>
                        </div>
                    </div>
                    <div className="person">
                        <div className="person__desc">
                            <div className="person__name">Kay Allardyce</div>
                            <div className="person__excerpt">
                                <p>
                                    Kay has been an Osteopath since 2005 and trained at The British College of
                                    Osteopathic Medicine. She is also qualified in modern acupuncture / dry needling,
                                    which is used for some musculo-skeletal problems. Kay is also a qualified Pilates
                                    instructor. Kay will do a thorough assessment initially, looking at posture,
                                    flexibility and spinal range of motion. Specific exercises are then prescribed
                                    helping to strengthen and mobilise according to your individual body needs.
                                </p>
                                <p>
                                    Kay performs weekly Pilates classes for groups, either online or in the clinic. Kay
                                    is a super-talented therapist who has an incredible ability to help many patients
                                    who adore her.
                                </p>
                            </div>
                        </div>
                        <div className="person__img">
                            <img src={person_4} alt=""/>
                        </div>
                    </div>
                    <div className="person">
                        <div className="person__desc">
                            <div className="person__name">Lucy</div>
                            <div className="person__excerpt">
                                <p>
                                    Lucy qualified as an Osteopath in 2013 and had treated a wide range of patients from
                                    different backgrounds from chronic pain to sports injuries both in the UK and abroad
                                    before joining the Croydon Physio team in 2017. She has been lucky enough to have
                                    met practitioners from all over the world who taught her skills she still uses in
                                    her practice today. Lucy bases her treatments not only on the latest research but on
                                    evidence-based practice and the principles of osteopathy. She uses a wide range of
                                    manual therapy techniques and rehab protocols to ensure each and every patient has a
                                    personalised and complete treatment plan. Lucy has completed her Yoga teacher
                                    training and clinical Pilates certification which compliments her clinical practice
                                    and helps empower patients in their movement and in their lives. Lucy will work with
                                    you to ensure you fully understand the diagnosis and treatment options. As an
                                    Osteopath, hands-on treatment is a large part of the treatment but Lucy understands
                                    that you can’t out-treat a poor lifestyle therefore will always encourage clients
                                    from being “patients” to becoming “active participants”
                                </p>
                            </div>
                        </div>
                        <div className="person__img">
                            <img src={person_11} alt=""/>
                        </div>
                    </div>

                    <div className="person">
                        <div className="person__desc">
                            <div className="person__name">Ryan</div>
                            <div className="person__excerpt">
                                <p>
                                    Ryan is a graduate of the University of Salford with a degree in Sport
                                    Rehabilitation and then he completed a Master's degree in Physiotherapy. Ryan is a
                                    clinical injury and exercise specialist and is very keen to help improve the lives
                                    of others getting you back on track to what matters most. Throughout his career,
                                    Ryan has worked in a number of different environments; from clinical work within
                                    Medical Centres and private practices, to working within the Ministry of Defence
                                    with our injured servicemen and women. Ryan also has experience working within
                                    sport, with London Irish Ladies, Sale sharks and Newport County FC. Ryan has a
                                    particular interest in all sporting injuries, lower limb tendon conditions and
                                    post-surgical rehabilitation.
                                </p>
                            </div>
                        </div>
                        {/*<div className="person__img">*/}
                        {/*    <img src={person_4} alt="" />*/}
                        {/*</div>*/}
                    </div>
                    {/*<div className="person">*/}
                    {/*    <div className="person__desc">*/}
                    {/*        <div className="person__name">Helen Waters</div>*/}
                    {/*        <div className="person__excerpt">*/}
                    {/*            <p>*/}
                    {/*                Helen graduated as a Physiotherapist from York St John University in 2010. She has since*/}
                    {/*                worked*/}
                    {/*                in the rehabilitation and medico-legal industry for 5 years; initially in a clinical*/}
                    {/*                governance*/}
                    {/*                role developing an audit and quality framework within a private healthcare company, and*/}
                    {/*                later*/}
                    {/*                moving into a management position with operational responsibility, focussing on the*/}
                    {/*                development*/}
                    {/*                of her clinical team and service delivery. During this time she also did part-time*/}
                    {/*                sports*/}
                    {/*                Physiotherapy with sports teams and charity events across the UK. She has now returned*/}
                    {/*                to*/}
                    {/*                clinical practice full time at Surrey Physio and looks forward to continuing to develop*/}
                    {/*                within*/}
                    {/*                the team.*/}
                    {/*            </p>*/}
                    {/*            <p>*/}
                    {/*                She is a keen sportsperson and has played badminton at a competitive level for over 15*/}
                    {/*                years,*/}
                    {/*                representing Northumberland county and, more recently, Yorkshire and local Surrey*/}
                    {/*                leagues.*/}
                    {/*            </p>*/}
                    {/*            <p>*/}
                    {/*                Outside of work she enjoys photography and loves the outdoors; spending as much time as*/}
                    {/*                she can*/}
                    {/*                camping, walking and travelling the country. Although now working and living in Surrey*/}
                    {/*                she*/}
                    {/*                remains a true northerner at heart!*/}
                    {/*            </p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="person__img">*/}

                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="person">*/}
                    {/*    <div className="person__desc">*/}
                    {/*        <div className="person__name">Jackson Ellis</div>*/}
                    {/*        <div className="person__excerpt">*/}
                    {/*            <p>*/}
                    {/*                Jackson qualified from St Georges University in London in 2011, and since then he worked*/}
                    {/*                in the*/}
                    {/*                NHS treating both adults and children. He has also worked with semi-professional men’s*/}
                    {/*                football*/}
                    {/*                teams, as well as for Professional Championship Club Millwall FC's youth Academy.*/}
                    {/*                Jackson has*/}
                    {/*                also trained and received qualifications in the instruction of Pilates for Low back Pain*/}
                    {/*                and*/}
                    {/*                Scoliosis. He also has a special interest in paediatric and adolescent musculoskeletal*/}
                    {/*                conditions, and Sports Injuries. Jackson is a fantastic member of the team, and we are*/}
                    {/*                really*/}
                    {/*                pleased to have him at Croydon Physio*/}
                    {/*            </p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="person__img">*/}
                    {/*        <img src={person_6} alt="" />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="person">*/}
                    {/*    <div className="person__desc">*/}
                    {/*        <div className="person__name">Elizabeth (Lizzy) Donnelly</div>*/}
                    {/*        <div className="person__excerpt">*/}
                    {/*            <p>*/}
                    {/*                Lizzy Donnelly is one of our newest physiotherapists and has previously worked in the*/}
                    {/*                NHS. Her first physiotherapy job was working for St Georges mental health trust. Her*/}
                    {/*                role was to work in the community and in the outpatient department. She has experience*/}
                    {/*                treating a variety of patients including patients in the community and in the outpatient*/}
                    {/*                setting. Lizzy decided to gain more experience working in the MSK outpatient department*/}
                    {/*                and was fortunate enough to work for Queen Mary’s hospital in Sidcup She took*/}
                    {/*                hydrotherapy classes in the local pool and covered other classes including Pilates.*/}
                    {/*                Lizzy has also worked for Hammersmith and Fulham Ruby club delivering pitch side*/}
                    {/*                assistance and treating acute sport injuries.*/}
                    {/*            </p>*/}
                    {/*            <p>*/}
                    {/*                Outside of the clinical setting Lizzy is passionate about sport and plays football for*/}
                    {/*                AFC Phoenix and competes in athletics for Herne Hill Harriers. Lizzy is passionate about*/}
                    {/*                health promotion and the benefits they can have to physical and mental health.*/}
                    {/*            </p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="person__img">*/}
                    {/*        <img src={person_7} alt="" />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </Page>);
    }
}
export default meetTeem;