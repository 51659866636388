import React from 'react';

import ForresterOsei1 from '../../access/img/Testimonials/ForresterOsei1.jpg';
import ForresterOsei2 from '../../access/img/Testimonials/ForresterOsei2.jpg';
import manchestermarathon from '../../access/img/Testimonials/manchestermarathon.jpg';
import img1 from '../../access/img/Testimonials/1.jpeg';
import img2 from '../../access/img/Testimonials/2.jpeg';
import img3 from '../../access/img/Testimonials/3.jpeg';
import img4 from '../../access/img/Testimonials/4.jpeg';
import img5 from '../../access/img/Testimonials/5.jpeg';
import img6 from '../../access/img/Testimonials/6.jpeg';
import img7 from '../../access/img/Testimonials/7.jpg';
import img8 from '../../access/img/Testimonials/8.jpeg';
import img9 from '../../access/img/Testimonials/9.jpeg';
import img10 from '../../access/img/Testimonials/10.jpeg';
import img11 from '../../access/img/Testimonials/11.jpeg';
import danny_davis from '../../access/img/Testimonials/danny_davis.jpeg';
import img12 from '../../access/img/Testimonials/12.jpeg';

import Page from "../module/include/Page/Page";

class Testimonials extends React.Component {
    state = {
        title: 'Testimonials'
    };

    render() {
        return (<Page title={this.state.title}>
            <section className="testimonials">
                <div className="container">
                    <div>
                        <em>
                            "Having had a lower back injury since my early twenties, I have visited a number of physios,
                            osteopaths and chiropractors over the past forty years. The best treatment by far has been
                            that
                            provided through Croydon Physio. On my first visit, I was walking with a stick and had been
                            in
                            constant pain for weeks. Regular treatment has transformed the situation, and returned me to
                            long periods of time that are totally pain-free. The professionalism and attention to
                            individual
                            needs that Tim Allardyce and his colleagues shows is exemplary and I gratefully recommend
                            them
                            most warmly."
                            <br/></em>
                        <strong>Dr Christopher Barnett,</strong> <br/> Headmaster, Whitgift School <br/> June 2017
                    </div>
                    <div style={{clear: 'both'}}></div>

                    <div>
                        <img width="281px" className="alignleft"
                             src={ForresterOsei1}/>
                        <img width="281px" className="alignleft"
                             src={ForresterOsei2}/>
                        <em>
                            "Tim and Peter have been very efficient in dealing with my problems and helping me to obtain
                            optimum
                            fitness. I have also been most impressed with the efficiency and courtesy of the helpful
                            staff at the
                            clinic for the past 2years.<br/>

                            I highly recommend Surrey physio to anyone who want to be cared for by a team of
                            professionals who are
                            also sympathetic, and enthusiastic about the nature of treatment and their well being of
                            the patient. "
                            <br/></em>
                        <strong>~Forrester Osei</strong>(2016 Commonwealth Champion)

                    </div>

                    <br clear="all"/>
                    <div><img width="281px" className="alignleft"
                              src={manchestermarathon}/>
                        <em>"I run with Striders of Croydon along with Alan Dolton and also see him for treatment
                            sometimes. At
                            the end of the last year I pushed too much on tired legs during a distance training run and
                            after a
                            Christmas timeout found that the usual rest/stretches had not done the trick. Alan
                            recommended seeing
                            an Osteopath and suggested you, Kay or Niamh and I booked an appointment with whoever was
                            the next
                            available. Niamh diagnosed the problem, treated me and set me exercises to do. Thanks to her
                            help I
                            was able to train for 3.5 weeks and do the Manchester marathon after 2 months away from
                            running. I
                            cannot praise Niamh enough for her expertise and her warm, friendly manner, simply
                            superb."<br/></em>

                        <strong>~Karim Akhtar</strong>

                    </div>
                    <br clear="all"/>
                    <div><br/>
                        <em>"I think it is us, your patients, who should thank you and your team for all the help
                            you have given
                            us over the years. I should give especial thanks for the last year in which your team’s
                            treatment of
                            both my arms has moved me from thinking I’d not be able to drive again back to virtual
                            normality. My
                            family and I wish all of you the very best."<br/></em>

                        <strong>~Anton Shott </strong>

                    </div>
                    <br clear="all"/>
                    <div>
                        <em>"My daughter had been successfully treated by Tim Allardyce for problems with her
                            back, neck and
                            shoulder so, when I developed severe pain in my right shoulder, she recommended that
                            I contact
                            Tim at Croydon Physio.<br/>
                            Tim diagnosed my problem as shoulder impingement. An MRI scan confirmed damage
                            to the rotator cuff
                            plus a 3mm tear, making it highly likely that I would need surgery. However,
                            with regular treatment
                            and ultrasound, Tim was able to restore full mobility to the shoulder without
                            the need for surgery.
                            I later developed severe pain in my left shoulder. An immediate appointment with
                            Tim revealed bursitis
                            and once again his treatment resulted in a full recovery. Tim and his team offer
                            a professional and
                            caring service and I would highly recommend them."<br/></em>

                        <strong>~Anne Brown</strong>, Sanderstead

                    </div>
                    <br clear="all"/>

                    <div>
                        <em>"I badly slipped a disc last year and started visiting Kay at 'Croydon Physio'
                            for
                            some much needed treatment on my back. Kay is very personable, patient and
                            calming
                            and I always leave feeling a lot better than when I arrived. She is also a great
                            pilates teacher and I really enjoyed her classes in the studio."<br/></em>

                        <strong>~Jennifer Corcoran</strong>

                    </div>
                    <br clear="all"/>


                    <div>
                        <em> "I have been working with Tim at Croydon Physio since 2005 after sustaining
                            a minor injury
                            10 days prior to a major championships, of which his quick diagnosis and
                            treatments allowed
                            me to compete successfully. From then on Tim took the time to understand my
                            sport attending
                            my training sessions and providing me with the support needed to improve my
                            back &amp; shoulder
                            strength and stability.<br/>
                            In 2015 I won my twentieth consecutive British Senior title, Tim's
                            support has been priceless
                            and one of the most important partners in my support network to
                            achieving my success and
                            maintaining a strong and healthy body for performance. He is more than
                            just a Physio, he is
                            someone who's opinion I truly respect and he understands the stresses
                            and strains weightlifting
                            puts on me, not only physically but mentally and is always there to
                            support through good and bad
                            times.<br/>
                            I have worked with many physio's over my career of 23 years and counting
                            in weightlifting,
                            some within World Class and Elite programmes, yet none have compared to
                            the professionalism,
                            experience and knowledge Tim provides when I'm on his treatment bed.<br/>
                            It has been a pleasure watching Tim grow his clinic and workforce to
                            support not only me but the
                            numerous elite athletes they work with, and I signpost anyone who wants
                            honest, professional and
                            quality treatments to him, including my own parents!!"</em>
                        <br/><strong>~Jo Calvino</strong><br/>
                        GB &amp; England Record Holder &amp; 20 times British Senior
                        Weightlifting<br/> Champion
                    </div>
                    <br clear="all"/>

                    <div>
                        <em> "I run with Striders of Croydon along with Alan Dolton and also see him
                            for treatment sometimes.
                            At the end of the last year I pushed too much on tired legs during a
                            distance training run and
                            after a Christmas timeout found that the usual rest/stretches had not
                            done the trick. Alan
                            recommended seeing an Osteopath and suggested you, Kay or Niamh and I
                            booked an appointment
                            with whoever was the next available. Niamh diagnosed the problem,
                            treated me and set me exercises
                            to do. Thanks to her help I was able to train for 3.5 weeks and do the
                            Manchester marathon
                            after 2 months away from running. I cannot praise Niamh enough for her
                            expertise and her
                            warm, friendly manner, simply superb. Best Wishes."</em>
                        <br/>
                        <strong>~Karim Akhtar</strong>
                    </div>
                    <br/>

                    <div><img width="281px" className="alignleft"
                              src={img1}/>
                        <em>"I was introduced to Tim by my Strength and Conditioning Coach Keith
                            Morgan
                            as he was highly recommended by my training partners at the gym. He
                            did not
                            disappoint, as with only 10 days to play with to get me in shape for
                            the
                            European Shooting Championships, he quickly identified the area
                            causing my
                            hip problem and his treatment worked rapidly. This put me in great
                            physical
                            shape for the competition and took away any niggling worries that
                            stuck in
                            the back of my head.<br/><br/>Regular physiotherapy is vital to my
                            sport, so
                            I look forward to working with Tim in the future as I look to
                            keep myself in
                            the best physical shape, so that I can qualify and compete in
                            the London
                            2016 Olympics. I would strongly recommend Tim as a
                            Physiotherapist to
                            anybody after the great experience I have had being treated by
                            him." </em>
                        <br/><strong>~Ken Parr</strong> - 2 x Silver Medal in Rifle Shooting 2010
                        Commonwealth
                        Games
                    </div>


                    <br/>

                    <div><img width="281px" className="alignleft"
                              src={img2}/>
                        <em>“I cannot thank Sarah and Tim enough for their personalised,
                            friendly,
                            expert service. I would recommend their service highly. In July
                            2007,
                            I experienced sudden, prolonged and severe leg pain. This
                            immediately
                            stopped me being able to function normally including playing
                            sports
                            or working, both major parts of my life. After reaching no
                            conclusions through a series of preliminary NHS appointments
                            (meantime, my health deteriorating), this was immediately
                            diagnosed
                            in August 2007 by Tim as a symptom of 3 prolapsed lower back
                            discs
                            (this was later clarified by an MRI scan and report which Tim
                            also
                            recommended). I have been receiving treatment, further
                            diagnoses,
                            guidance and advice from Tim and Sarah since this time and I’m
                            happy to say that I’m on my way to full recovery. Without these
                            interventions, it was extremely likely that my health would have
                            deteriorated rapidly and I would have had to have lower back
                            surgery,
                            both likely to have a more adverse affect on the quality of my
                            life".</em><br/><br/>
                        <strong>~John Herbert</strong> (came to us with three badly
                        prolapsed lower back discs, confirmed on MRI, and a likely surgical
                        case)
                    </div>
                    <br/>

                    <div><img width="281px" className="alignleft"
                              src={img3}/>
                        <em>"I had a knee operation to repair the anterior cruciate
                            ligament and
                            meniscus in July 2006. I started my rehab at croydon physio,
                            Addington
                            Palace. I am back to playing field hockey and skiing and all
                            my sporting
                            activities as I did before the accident.<br/><br/>What
                            impressed me most
                            about the clinic was not only their expert knowledge but
                            also the personal
                            care and attention to detail. I felt I was not just
                            another patient, but an
                            individual and I got tailor-made treatment. The staff
                            constantly encouraged
                            and motivated me, and nothing seemed to be too much
                            trouble for them. I’m
                            very thankful for all their help and support. I highly
                            recommend the clinic
                            for their professionalism and care."</em><br/> <strong>~A.
                            Sharma </strong> - Pharmacist,
                        recommended by a local surgeon
                    </div>
                    <p><br clear="all"/><br/></p>

                    <div><img width="281px" className="alignleft"
                              src={img4}/>
                        The Mayor of Croydon after treatment
                    </div>
                    <p><br clear="all"/><br/></p>

                    <div><img width="281px" className="alignleft"
                              src={img5}/>
                        <em>"I was introduced to Tim back in September 2005 when he came
                            to visit my
                            home training venue at Crystal Palace to see Keith Morgan,
                            my coach. At this
                            point I was preparing for the Commonwealth Championships in
                            the October,
                            when 1 week before I was due to leave an extremely painful
                            injury occured to
                            my back and upper leg. It was from this point Tim became an
                            important part
                            of Team Calvino, with a real challenge ahead of him to fix
                            me in 7 days!!.
                            After the first visit I instantly felt better and responded
                            quickly to Tim's
                            treatment to feel fully fit heading out to my event.<br/>Leading
                            up to
                            Commonwealth Games 2006 in Melbourne it was important
                            that I maintained
                            physical fitness &amp; well being to achieve my peak
                            performance at the
                            games, Tim's treatment was a major role in achieving
                            this. He not only takes
                            on board your injuries, he takes on the requirements of
                            your sport to ensure
                            any weaknesses are fazed out to allow you to be at your
                            best, and I
                            definitely benefitted from this. He is totally 100%
                            committed to you no
                            matter how big or small your problems may be, Tim will
                            help you gain the
                            results needed to conquer your goals and without his
                            support I don't think I
                            would have had so many good years of competitions. I
                            will enjoy working with
                            Tim for many years to come".</em><br/> <strong>~Jo
                            Calvino</strong> - Britain's top female weightlifter
                    </div>
                    <br/><br/>

                    <div><img width="281px" className="alignleft"
                              src={img6}/>
                        <em>"I had the pleasure of meeting Tim in June 2005 when
                            came back from an
                            international in Norway. I was told my season was over
                            because my adductor
                            muscle had come away from the bone. Naturally I was
                            devastated because I had
                            an excellent winter and all I wanted to do was
                            throw.<br/>I met Tim at
                            Crystal Palace National Centre where he said he
                            would like to attempt to fix
                            me because he was confident that he had the relevant
                            equipment and
                            experience.<br/>Being a sceptic and having nothing to
                            lose I went along
                            and saw Tim and hand on heart Tim was wonderful
                            and he had me throwing a
                            again not long after a number of rehab
                            secessions and as a result I finished
                            the season 4th in England. I am eternally
                            grateful to Tim and his team. I
                            cant bring to words how wonderful I felt after
                            seeing Tim.<br/>I would
                            strongly recommend Tim and his team to
                            anyone with any injury regardless of
                            wether you compete at an amateur level or
                            world class. I look
                            forward to continuing to work with Tim
                            throughout my athletics career".</em><br/>
                        <strong>~Abdul
                            Buhari</strong><br/> GB International Discus Thrower,
                        currently rank no.2 in UK
                    </div>

                    <br/>

                    <div><em>"Dear Tim, I read your email newsletter with
                        immense interest. I am a very satisfied patient of
                        yours.
                        In fact my thanks is to Dr Wilcock for recommending you
                        to me. I have now recommended you to two of my friends.
                        My attendance for two sessions for my back discomfort
                        gave me great
                        relief. Thank you for your good work."</em><br/><strong>~Vinod
                        Nair</strong><br/><br/>

                        <em>"I am an old hand at osteopathic treatment, due to a
                            long-term disc problem in my neck, and have always
                            been convinced of its benefits.
                            However, the recent injury to my right shoulder has
                            been something of a new experience. I know from a
                            previous incident several years ago that such
                            injuries can take many months to heal, but Tim has
                            been using
                            an interesting and innovative combination of
                            osteopathic and physiotherapy techniques on me, and
                            the shoulder
                            seems to have responded well to this. It seems
                            highly likely that the recovery time will be
                            significantly
                            reduced as a result."</em><br/><strong>~Roger L.
                            Shepherd</strong><br/><br/>

                        <em>"I first went to see Tim a few years back after
                            suffering a Grade 3 separation of my right A/C
                            joint.
                            Rehab treatment for the injury had been non-existent
                            on the NHS where I lived in Guildford and
                            physiotherapy
                            wasn’t really helping. <br/>I saw an article on Tim
                            in Golf
                            Punk magazine and decided to get in
                            touch. From the first minute of the first
                            session, Tim came across as a very confident and
                            capable
                            individual who very re-assuringly said he
                            could sort the injury out in 6 sessions, and
                            that surgery would not be needed. The day after
                            the first
                            session I felt like I’d done 10
                            rounds with Tyson and had the same sensation
                            until about session number 4. However, by the
                            6th session, and
                            true to his word, the shoulder area
                            felt stable and comfortable for the first time
                            since suffering the injury. <br/>Tim has a
                            holistic
                            approach to rehabilitation from injury
                            which combines highly effective treatment
                            with a tailor made exercise regime. I’ve
                            been treated by Tim
                            for 4 years and continue to see him
                            regularly for check ups and treatment to
                            other minor niggles, even though I moved to
                            North Yorkshire 2 years
                            ago. <br/>Tim’s expertise
                            has been integral to me achieving my
                            health, fitness and sporting goals and I
                            cannot recommend him highly
                            enough."</em><br/><strong>~Phil
                            Shenton</strong> - Harrogate, North Yorks
                        <p><br clear="all"/></p>

                        <div><img width="281px" className="alignleft"
                                  src={img7}/>
                            <em>Tim began treating me in 2004 after the birth of
                                my twins. Since then he’s treated the whole
                                family.
                                The twins saw Tim at each important
                                developmental milestone to ensure that their
                                spines were perfectly aligned to give them the
                                best start in
                                life. My eldest son suffers from
                                Irritable Hip Syndrome and has had great relief
                                following Tim’s treatment. The combination of
                                Osteopathy and Physiotherapy gives great
                                versatility to Tim’s treatment and we are very
                                happy with the care we’ve had!”
                            </em><br/><strong>~Ros Wood</strong>

                        </div>
                        <p><br clear="all"/></p>

                        <div><em>"I was recommended to Tim by my GP, after
                            complaining of restricted movement in my shoulderand
                            arm.After my initial
                            consultation, Tim passed me over into the superb
                            care of Sarah, whomanaged to increase mobility but
                            could not completely
                            remove the pain in my joint. It then transpired that
                            an operation would be the only way to treat my
                            complaint. I had this in June 2007, and
                            was very happy with the result.<br/>
                            However in order to regain the mobility I had
                            lost due to the surgery, I once again had the
                            pleasure of
                            Sarah manipulating my arm in all sorts of
                            different directions.Both Tim and Sarah made me
                            feel very welcome and more than just
                            another patient. In fact Tim has been happy to
                            show off my completely recovered shoulder to his
                            other patients experiencing the same
                            problem, so they know what to expect, and how
                            well they will be able to regain mobility and
                            strength after treatment. I was so happy
                            with the treatment I received, I have already
                            recommended Sarah and Tim to one of my friends
                            who had a problem with his back, no
                            doubt he is only the first of many."
                        </em><br/><strong> ~ Robbie Horne</strong><br/><br/>


                            <em>"I am 44 year old woman who woke up one morning
                                with pain in my left shoulder. I was bewildered
                                and very worried to find that
                                I could not raise my arm more than a few inches.
                                I hoped that it would just get better on its own
                                but realised after a
                                worrying few days that was not going to happen.
                                After a referral from my GP I saw Tim Allardyce
                                for a consultation and he
                                diagnosed me with shoulder impingement. I had a
                                series of treatments with Tim in which he
                                treated me as a whole person reassuring
                                me as I was very fearful of having surgery or
                                never being able to use my arm properly again.
                                At the end of the treatment
                                Tim had restored my shoulder to complete health
                                and mobility. I guess we never fully appreciate
                                our bodies until they get
                                sick, hurt or don't work properly anymore. Every
                                time I raise that arm now I am so grateful to
                                tim for his professional
                                expertise, knowledge and excellence in his
                                vocation as physiotherapist and osteopath, and
                                that which I believe cannot be
                                taught, his human compassion for his patients be
                                they athlete or hurting house wife.Thanks Tim
                                you are a star</em>
                            <br/><strong>~ Di Newberry</strong>
                            <br/><br/>

                            <em>"Tim, Sorry I didn't get to see you
                                again just before I left for my trip but
                                I was told you weren't available and
                                Niamh
                                was very helpful in your stead. I also
                                wanted to just pass on my thanks
                                indirectly through you for some
                                telephone support
                                that Sarah has given me over the past 48
                                hours... I pulled my left medial calf
                                muscle in one of the group fitness
                                sessions
                                (stupid I know... not enough warm-up
                                stretching for an old fatty like me).
                                <br/>She was invaluable in the immediate
                                few hours in getting me to a point
                                where the swelling is almost zero
                                (despite my Warfarin) and I can
                                already walk reasonably cleanly.
                                I've missed one day of the training
                                yesterday but
                                thanks to Sarah I might feel up to
                                given it a little gentle try out on
                                Monday. You've got a great team
                                there.
                                Thanks to all three of you for
                                aiding a big lummux."</em>
                            <br/><strong>~Chris Glynne</strong><br/>
                        </div>
                        <br/>

                        <div><img width="281px" className="alignleft"
                                  src={img8}/>
                            <em>"A little under two and a half years ago,
                                Chris Martin became the 30th
                                person ever to row solo across the Atlantic
                                Ocean. Whilst competing against
                                crews who mostly had two rowers (including
                                the auspicious pairing of Ben
                                Fogle and James Cracknell) Chris didn't have
                                the smoothest of journeys.
                                Plagued by equipment failure from the start
                                the list of broken items onboard
                                the boat included the watermaker (that turns
                                sea water into drinking water),
                                several of his oars, the rudder, his GPS
                                system and navigation light. He was
                                able to mend several of these but also
                                suffered a major setback when severe
                                back pain made the lightest of paddling
                                extremely painful. With help from
                                Tim Allardyce giving advice on how to best
                                relieve and stretch his back over
                                the sattelite telephone, Chris was able to
                                finish the challenge. Something
                                that without Tim's help would have been a
                                far more painful experience.
                            </em></div>
                        <em>
                            <br/>

                            <div><img width="281px"
                                      className="alignleft"
                                      src={img9}/>
                                What now for this entrepid explorer from
                                London? Well Chris is going
                                back out again, this time in a double
                                handed boat and with Mick Dawson
                                they aim to be the first team ever to
                                row unsupported across the North
                                Pacific Ocean. The crew will set off in
                                Choshi, Japan and aim to row
                                until they land, over 5000 miles away,
                                in San Fransisco, USA. Being
                                unsupported for a trip that could take
                                up to 6 months is a daunting
                                prospect but especially so with Chris'
                                history of back pain. As part of
                                preparations for the trip that will take
                                commence at the end of April
                                2009 Chris has been seeing Tim Allardyce
                                for regular physio treatment,
                                instruction and advice to prevent the
                                same debilitating pain as he
                                suffered on his previous voyage. If
                                you'd like to sponsor Chris and
                                Mick or would just like more information
                                on the challenge why not have
                                a look at their website <a
                                    href="http://www.goldengateendeavour.com/">Golden
                                    Gate
                                    Endeavour</a>"
                            </div>
                            <br/><br/>

                        </em>
                        <div><em><img width="281px" className="alignleft"
                                      src={img10}/>
                            “I had the pleasure of meeting Tim and his team
                            late 2007, when
                            during training I suffered a hip flexor tear.
                            Thinking I was at an
                            end Tim was recommended to me via my
                            conditioning coach at Crystal
                            Palace, Keith Morgan. <br/><br/>After seeing Tim
                            for treatment, I
                            returned to full training status three weeks
                            later, allowing me to
                            return to retain my British U23 title. Since
                            then Joe and Tim have
                            helped me with any injuries and kept me fit
                            enough to win silver at
                            the Olympic Trials in 2008, narrowly missing out
                            on Olympic
                            selection.<br/><br/>I have also put my speedy
                            recovery down to
                            regularly drinking For Goodness Shakes who
                            have continued supporting
                            me with the no1 recovery drink. FGS plays a
                            vital role in my day-day
                            life, allowing me to meet the demands of my
                            nutritional
                            requirements. Whilst training at numerous
                            locations around London
                            FGS is athlete friendly fitting in any
                            training bag and tasting
                            great! Whilst travelling for treatment FGS
                            allowed me to consume
                            vital carbohydrate and proteins after
                            training and treatment to aid
                            in my recovery.<br/>I cannot express how
                            thankful I am to Tim
                            and Joe for the work and time they have
                            given me over the past 2
                            years; I would strongly recommend any
                            member of the
                            Team.”</em>
                            <br/><strong>~Amir Williamson</strong> GB
                            International Hammer
                            Thrower<br/><br/><em>Dear Tim, as you must
                                realise your treatment has
                                been fantastic as I have not needed any
                                help since March. We had a
                                great time on our cruise, Singapore to
                                Southampton , and another
                                completely different holiday walking in
                                Northumberland in June.The
                                summer has been spent working in the
                                garden,mainly in the vegetable
                                patch. In October we are going walking
                                in Jersey for 5 days and hope
                                the weather will be dry. regards
                                Jeanne.</em><br/><strong>~Dr J.
                                Brett,</strong>
                            Rheumatologist at Mayday
                            Hospital<br/><br/>

                            <div><img width="281px"
                                      className="alignleft"
                                      src={img11}/>
                                <em>"I was correctly diagnosed
                                    with a compression fracture
                                    in my spine by Tim
                                    Allardyce about 2 years ago.
                                    Since then his skillful
                                    treatment has
                                    gradually improved my
                                    condition so that I can walk
                                    about a mile without a
                                    walking stick and negotiate
                                    stairs happily.<br/>I do not
                                    know how I would
                                    have managed without his
                                    support , knowledge and
                                    encouragement.<br/>Thank
                                    you so much,
                                    Tim."</em><br/><strong>~June
                                    Posner (Mrs P)</strong><br/>
                                <br/>
                                <em>"I just wanted to say a
                                    quick thank you for
                                    allowing me to have
                                    treatment with
                                    yourselves.
                                    <br/>Matt was very good.
                                    He taught me and
                                    showed me things I
                                    never knew about my
                                    legs. For example
                                    not
                                    realising I have
                                    slightly bowed legs
                                    and that my feet
                                    supinated. I was
                                    only ever treated
                                    for the actual knee
                                    pain by Doctor's in
                                    the past (with
                                    Arthroscopies etc),
                                    my bio-mechanics and
                                    the reasons why i
                                    was actually getting
                                    the pain was never
                                    really looked at.
                                    So it was very
                                    pleasing that Matt
                                    did this and I now
                                    know the reasons for
                                    the way my legs /
                                    knees are the way
                                    they are. He found
                                    some shoe insoles
                                    online for me, which
                                    I am now wearing.
                                    This, along with the
                                    hands-on
                                    massaging of the
                                    muscles in the legs
                                    and laser treatment
                                    on the knee, has
                                    pretty much
                                    completely cleared
                                    the every day knee
                                    pain that I was
                                    experiencing. I hope
                                    to return in a few
                                    weeks time to see
                                    Matt again for a
                                    further review on
                                    how I am getting
                                    on. <br/>The
                                    facilities are
                                    very
                                    good and the
                                    staff at the
                                    clinic are very
                                    friendly. Can
                                    you, if
                                    possible,
                                    personally give
                                    Matt my thanks
                                    for helping me
                                    get to the stage
                                    I am at with
                                    my legs, a stage
                                    I never thought
                                    was possible.
                                    Once again, many
                                    thanks and
                                    I hope to see
                                    you again in a
                                    few weeks time."
                                </em><br/>Kind regards, <br/>
                                <strong>~Bobby Lock</strong>

                                <br/><br/>
                                <img width="281px"
                                     className="alignleft"
                                     src={danny_davis}/>
                                <em>"My name is
                                    Daniel Davis, I
                                    am the best
                                    hurdler in the
                                    UK Under 23
                                    years old,
                                    and I first
                                    started working
                                    with Tim in
                                    2004. At 16
                                    years old I had
                                    just
                                    become the
                                    National
                                    Champion in the
                                    Sprint Hurdles,
                                    a championship I
                                    had
                                    gone into and
                                    won without
                                    knowing I was
                                    suffering from a
                                    very severe
                                    chronic groin
                                    strain. My
                                    injury had been
                                    undiagnosed all
                                    season and
                                    although I had
                                    won Gold, I felt
                                    I had
                                    underperformed
                                    because the
                                    groin
                                    strain was
                                    holding back my
                                    performance.
                                    Over the next 8
                                    months Tim and I
                                    worked
                                    tirelessly on
                                    getting my
                                    injury healed,
                                    and focused on
                                    conditioning
                                    my body to
                                    perform better
                                    than it
                                    previously ever
                                    had. <br/>Now at
                                    20
                                    years old
                                    with thanks
                                    to Tim, I
                                    have made a
                                    major
                                    breakthrough
                                    in my senior
                                    athletics
                                    career
                                    having. I
                                    had an
                                    excellent
                                    indoor
                                    season
                                    finishing up
                                    being a mere
                                    0.15 seconds
                                    away from
                                    qualifying
                                    for the
                                    World Indoor
                                    Championships
                                    in Valencia.<br/>Working
                                    with Tim
                                    has
                                    improved
                                    my
                                    physical
                                    condition
                                    and
                                    revolutionised
                                    my
                                    attitude.
                                    Those of
                                    you that
                                    have
                                    had the
                                    pleasure
                                    of
                                    meeting
                                    him will
                                    have
                                    experienced
                                    his
                                    infectious
                                    enthusiasm,
                                    optimism
                                    and goal
                                    orientated
                                    work
                                    ethics
                                    which
                                    are the
                                    hallmarks
                                    of a
                                    champion
                                    in any
                                    field.
                                    We work
                                    so well
                                    together
                                    because
                                    we
                                    share
                                    the same
                                    desire
                                    for
                                    excellence
                                    in our
                                    chosen
                                    areas."
                                </em><br/>
                                <strong>Danny
                                    Davis</strong>

                                <br/><br/>
                                <em>"I was
                                    referred to
                                    Tim
                                    Allardyce by
                                    my
                                    orthopaedic
                                    consultant
                                    at St
                                    Anthony's
                                    hospital,
                                    when many
                                    other
                                    treatments
                                    had not
                                    relieved my
                                    ongoing
                                    symptoms.
                                    Tim
                                    addressed
                                    the route of
                                    my problem
                                    and
                                    continuing
                                    pain cycle.
                                    I knew what
                                    to expect
                                    and he gave
                                    me a
                                    realistic
                                    timescale
                                    and plan for
                                    recovery. He
                                    enabled me
                                    to start
                                    feeling pain
                                    free
                                    for the
                                    first time
                                    following a
                                    severe car
                                    accident,
                                    years
                                    before.<br/>Tim
                                    worked
                                    excellently
                                    with his
                                    team to
                                    aid my
                                    recovery.
                                    He
                                    communicated
                                    closely
                                    with his
                                    rehab
                                    specialist,
                                    who
                                    provided
                                    a great
                                    rehab
                                    programme
                                    of
                                    exercise
                                    and
                                    improved
                                    my
                                    muscle
                                    strengthening."</em>
                                <br/>

                                <strong>~Julia
                                    Baker</strong>


                                <br/>

                                <br/><em>I
                                    went
                                    to
                                    Croydon
                                    Physio
                                    after
                                    my
                                    left
                                    knee
                                    had
                                    swelled
                                    up
                                    following
                                    a
                                    tackle
                                    I
                                    received
                                    while
                                    playing
                                    football.
                                    Julia
                                    diagnosed
                                    it
                                    as
                                    most
                                    likely
                                    a
                                    torn
                                    meniscus
                                    and
                                    that
                                    surgery
                                    was
                                    an
                                    option
                                    if
                                    it
                                    didn't
                                    heal.
                                    But
                                    Julia
                                    worked
                                    wonders
                                    on
                                    it
                                    and
                                    I
                                    was
                                    back
                                    playing
                                    football
                                    within
                                    6
                                    weeks
                                    pain
                                    free.
                                    Thanks
                                    so
                                    much
                                    for
                                    getting
                                    my
                                    knee
                                    back
                                    in
                                    shape
                                    so
                                    quickly.</em>
                                <br/><strong>~Perry
                                    Wright</strong><br/>

                                <br/>
                                <em>I
                                    was
                                    suffering
                                    for
                                    a
                                    long
                                    time
                                    with
                                    terrible
                                    shoulder
                                    pain.
                                    I
                                    have
                                    had
                                    some
                                    treatments
                                    including
                                    laser
                                    ,
                                    but
                                    nothing
                                    seemed
                                    to
                                    help.
                                    In
                                    desperation
                                    I
                                    started
                                    to
                                    surf
                                    the
                                    Internet,
                                    and
                                    came
                                    upon
                                    Tim's
                                    web
                                    site.
                                    After
                                    contacting
                                    him
                                    and
                                    doing
                                    his
                                    exercises,
                                    I
                                    am
                                    now
                                    almost
                                    free
                                    from
                                    shoulder
                                    pain,
                                    and
                                    can
                                    do
                                    all
                                    the
                                    things
                                    I
                                    could
                                    not
                                    do
                                    before.
                                    Words
                                    are
                                    inadequate
                                    to
                                    express
                                    my
                                    my
                                    appreciation
                                    to
                                    Tim
                                    for
                                    all
                                    that
                                    he
                                    has
                                    done
                                    for
                                    me.
                                    He
                                    has
                                    given
                                    me
                                    back
                                    my
                                    life
                                    after
                                    only
                                    just
                                    three
                                    months.
                                    I
                                    do
                                    not
                                    hesitate
                                    to
                                    recommend
                                    Tim
                                    to
                                    anyone
                                    needing
                                    his
                                    services.
                                    Thank
                                    you
                                    Tim. </em><br/><strong>~W.
                                    Johnson</strong>,
                                Ontario,
                                Canada
                                <br/><br/>
                                <em>Just
                                    to
                                    say
                                    a
                                    big
                                    thank
                                    you
                                    to
                                    Matt.
                                    I
                                    have
                                    suffered
                                    from
                                    lower
                                    back
                                    for
                                    for
                                    so
                                    long
                                    and
                                    in
                                    6
                                    short
                                    weeks
                                    i
                                    can
                                    now
                                    do
                                    things
                                    that
                                    i
                                    would
                                    have
                                    struggled
                                    with
                                    in
                                    the
                                    past,
                                    just
                                    getting
                                    out
                                    of
                                    the
                                    car
                                    was
                                    a
                                    task.
                                    I
                                    had
                                    been
                                    refered
                                    to
                                    the
                                    hospital
                                    physio
                                    they
                                    treated
                                    me
                                    with
                                    acupuncture
                                    which
                                    would
                                    ease
                                    it
                                    for
                                    a
                                    while
                                    but
                                    never
                                    got
                                    to
                                    the
                                    root
                                    of
                                    the
                                    problem.
                                    But
                                    once
                                    again
                                    thanks
                                    for
                                    putting
                                    a
                                    spring
                                    back
                                    into
                                    my
                                    step.</em>
                                <br/><strong>~Mary
                                    Walker</strong>

                                <br/><br/>
                                <em>"A
                                    couple
                                    months
                                    ago
                                    Matt
                                    treated
                                    me
                                    for
                                    injuries
                                    for
                                    my
                                    back
                                    and
                                    shoulder
                                    and
                                    recently
                                    contacted
                                    me
                                    to
                                    see
                                    how
                                    I
                                    was
                                    progressing.
                                    During
                                    the
                                    treatment
                                    I
                                    was
                                    very
                                    impressed
                                    with
                                    his
                                    skills
                                    and
                                    concern
                                    for
                                    my
                                    health.
                                    After
                                    a
                                    few
                                    treatments
                                    he
                                    suggested
                                    I
                                    seek
                                    consultation
                                    with
                                    a
                                    specialist
                                    since
                                    the
                                    injuries
                                    did
                                    not
                                    seem
                                    to
                                    be
                                    improving.
                                    Good
                                    advice
                                    since
                                    unfortunately
                                    MRI
                                    scans
                                    revealed
                                    cancer
                                    lesions
                                    in
                                    both
                                    areas
                                    and
                                    I
                                    am
                                    now
                                    undergoing
                                    treatment
                                    for
                                    this.Thanks
                                    again
                                    to
                                    Matt
                                    and
                                    your
                                    team"</em><br/><strong>~John
                                    Robinson</strong>
                                <br/><br/>

                                <em>I
                                    am
                                    a
                                    Sport &amp; Remedial
                                    Massage
                                    Therapist,
                                    and
                                    have
                                    always
                                    referred
                                    my
                                    clients
                                    to
                                    the
                                    the
                                    Croydon
                                    Physio
                                    Clinic.
                                    Part
                                    of
                                    my
                                    work
                                    is
                                    to
                                    know
                                    when
                                    to
                                    refer
                                    a
                                    patient
                                    who
                                    needs
                                    assessment
                                    from
                                    an
                                    osteopath
                                    or
                                    physiotherapist.
                                    Since
                                    receiving
                                    treatment
                                    myself
                                    from
                                    the
                                    clinic
                                    I
                                    have
                                    always
                                    been
                                    happy
                                    to
                                    recommend
                                    to
                                    all
                                    clients
                                    and
                                    friends.
                                    They
                                    always
                                    deliver
                                    professional,
                                    friendly
                                    treatment
                                    with
                                    great
                                    feedback
                                    from
                                    my
                                    clients.
                                    Another
                                    attribute
                                    to
                                    the
                                    clinic
                                    is
                                    they
                                    have
                                    a
                                    large
                                    network
                                    of
                                    other
                                    professional
                                    contacts
                                    ranging
                                    from
                                    exercise
                                    professionals
                                    to
                                    consultants
                                    which
                                    makes
                                    it
                                    an
                                    extremely
                                    efficient
                                    and
                                    knowledgeable
                                    environment
                                    where
                                    you
                                    find
                                    the
                                    right
                                    person
                                    for
                                    you
                                    need.</em><br/><strong>~Mel
                                    Rabjohns</strong>
                                <br/>
                            </div>
                            <br/>

                            <div><img width="281px"
                                      className="alignleft"
                                      src={img12}/>
                                <em>"When I was referred to
                                    Tim Allardyce at Croydon
                                    Physio I was in constant
                                    severe pain from a neck
                                    injury to such an extent
                                    that I couldn’t
                                    imagine life getting
                                    back to normal without
                                    surgery. Incredibly,
                                    just eight
                                    sessions later I’m
                                    almost completely
                                    recovered. It’s like
                                    some
                                    kind of magic. I
                                    wouldn’t hesitate in
                                    recommending Tim to
                                    anyone".</em><br/>

                                <strong>Greg Cheasman was
                                    referred by a local GP
                                    with a cervical
                                    disc prolapse.</strong>
                                <br/><br/>

                                <em>"I came to see Joe
                                    in April 2008 with a
                                    REALLY
                                    painful tennis
                                    elbow. He worked
                                    wonders on it with
                                    physio, ultra sound
                                    and
                                    acupuncture and 7
                                    sessions saw me as
                                    right as rain!<br/>I
                                    returned for a
                                    couple of
                                    sessions this
                                    year when the
                                    elbow began to
                                    give me a few
                                    twinges!
                                    And again Joe
                                    worked the
                                    oracle!"</em><br/><strong>~Mary
                                    Bassett-Cross</strong>

                                <br/><br/>

                                <em>"Since October
                                    2008 I have
                                    suffered from a
                                    severe hamstring
                                    tear which, for
                                    an unknown
                                    reason, wouldn't
                                    heal. After
                                    seeing an
                                    osteopath,
                                    chiropractor,
                                    and a physio the
                                    condition still
                                    didn't
                                    recover. I
                                    therefore
                                    decided to take
                                    the next step
                                    and see a
                                    consultant.
                                    After initial
                                    diagnostics and
                                    even surgery,
                                    the problem was
                                    still the same
                                    and I was back
                                    to square
                                    one.<br/><br/>After
                                    being
                                    referred by
                                    a friend to
                                    Croydon
                                    Physio, I
                                    started
                                    seeing Matt
                                    in January
                                    2010 by
                                    February
                                    2010 my
                                    hamstring
                                    problem was
                                    completely
                                    healed!
                                    Throughout
                                    this time I
                                    was
                                    continually
                                    impressed
                                    with Matt's
                                    dedication,
                                    tenacity and
                                    enthusiasm.
                                    On
                                    more one
                                    occasion,
                                    Matt would
                                    begin the
                                    conversation
                                    with "Hello
                                    matey,
                                    I've been
                                    thinking
                                    about your
                                    problem,
                                    and...".
                                    Matt always
                                    went out of
                                    his
                                    way to help
                                    me and did
                                    everything
                                    he could to
                                    get to the
                                    bottom of my
                                    problem. <br/><br/>Some
                                    people
                                    believe
                                    that
                                    Physio's
                                    prolong
                                    treatment
                                    as
                                    long as
                                    possible
                                    in order
                                    to make
                                    the most
                                    money
                                    from
                                    their
                                    patients.
                                    However,
                                    I have
                                    no
                                    problem
                                    in
                                    telling
                                    people
                                    that
                                    Matt's
                                    only aim
                                    is to
                                    heal his
                                    patients
                                    as
                                    quickly
                                    and as
                                    thoroughly
                                    as
                                    possible.
                                    I have
                                    no
                                    reservations
                                    in
                                    coming
                                    back to
                                    Matt
                                    again
                                    and, as
                                    a keen
                                    member
                                    of
                                    Warlingham
                                    Cricket
                                    Club and
                                    Epsom
                                    Hockey
                                    Club. I
                                    will be
                                    recommending
                                    him
                                    to
                                    anyone
                                    that
                                    needs
                                    treatment. <br/><br/>After
                                    nearly
                                    living
                                    with
                                    my
                                    problem
                                    for
                                    the
                                    rest
                                    of
                                    my
                                    life
                                    Matt
                                    physically,
                                    and
                                    psychologically,
                                    bought
                                    me
                                    back
                                    to
                                    life!"</em>

                                <br/><strong>~Andrew
                                    McNamee</strong>

                                <br/><br/><em>"Following
                                    key-hole
                                    surgery on
                                    my shoulder
                                    I found that
                                    the
                                    physiotherapy
                                    regime
                                    suggested by
                                    the hospital
                                    appeared to
                                    be
                                    relatively
                                    ineffective
                                    in improving
                                    my
                                    mobility. I
                                    therefore
                                    signed up
                                    with Croydon
                                    Physio. The
                                    initial
                                    diagnostic
                                    session was
                                    very
                                    thorough and
                                    the
                                    programme of
                                    exercises
                                    has resulted
                                    in a
                                    significant
                                    improvement
                                    in my arm
                                    movements.
                                    It was down
                                    to the
                                    physiotherapy
                                    treatment I
                                    had from you
                                    and I wish
                                    to say how
                                    grateful I
                                    am." Stephen
                                    Bland<br/><br/>"We
                                    have
                                    settled
                                    back
                                    home in
                                    Cleethorpes
                                    and
                                    just
                                    thought
                                    I write
                                    to say
                                    that
                                    your
                                    services
                                    will be
                                    sorely
                                    missed
                                    (no
                                    pun
                                    intended)
                                    both
                                    myself
                                    and my
                                    wife
                                    Karen
                                    had
                                    excellent
                                    service
                                    from you
                                    and your
                                    team.
                                    Karen is
                                    especially
                                    grateful
                                    to Niamh
                                    for her
                                    work on
                                    her
                                    frozen
                                    shoulder
                                    which
                                    enabled
                                    her to
                                    avoid
                                    surgery!
                                    I will
                                    always
                                    be
                                    grateful
                                    to you
                                    Tim for
                                    sorting
                                    my back
                                    out when
                                    I’d
                                    prolapsed
                                    a disc
                                    and will
                                    miss our
                                    regular
                                    sessions
                                    to keep
                                    my lower
                                    back in
                                    order. I
                                    am
                                    convinced
                                    that you
                                    and your
                                    team
                                    saved us
                                    from
                                    further
                                    stress
                                    and
                                    pain".
                                    Chris
                                    Pinkney. <br/><br/>"I
                                    was
                                    refered
                                    for
                                    physio
                                    to
                                    help
                                    me
                                    with
                                    my
                                    shoulder
                                    problem
                                    after
                                    a
                                    traffic
                                    accident.<br/>When
                                    I
                                    first
                                    went
                                    to
                                    see
                                    Julie,
                                    I
                                    was
                                    in
                                    a
                                    state
                                    that
                                    I
                                    wasn't
                                    even
                                    able
                                    to
                                    move
                                    my
                                    arm
                                    properly.
                                    After
                                    a
                                    few
                                    sessions
                                    with
                                    Julie,
                                    I
                                    was
                                    in
                                    a
                                    position
                                    that
                                    I
                                    was
                                    able
                                    to
                                    move
                                    my
                                    arm
                                    better
                                    than
                                    I
                                    did.
                                    As
                                    my
                                    right
                                    shoulder
                                    was
                                    extremily
                                    painful,
                                    I
                                    didn't
                                    feel
                                    the
                                    pain
                                    in
                                    my
                                    left
                                    shoulder.
                                    Julie
                                    very
                                    kindly
                                    spend
                                    time
                                    helped
                                    me
                                    on
                                    my
                                    left
                                    shoulder
                                    as
                                    well
                                    as
                                    right.
                                    My
                                    pain
                                    improved
                                    by
                                    the
                                    end
                                    of
                                    the
                                    9th
                                    session
                                    and
                                    the
                                    credit
                                    goes
                                    to
                                    Julie.
                                    She
                                    made
                                    my
                                    condition
                                    much
                                    better
                                    and
                                    I
                                    was
                                    extremely
                                    happy
                                    with
                                    the
                                    tretment.
                                    She
                                    is
                                    very
                                    friendly,
                                    helpful
                                    and
                                    is
                                    very
                                    easy
                                    to
                                    talk
                                    to.<br/>She
                                    always
                                    listen
                                    to
                                    patient's
                                    need
                                    and
                                    does
                                    everything
                                    she
                                    can
                                    do
                                    to
                                    meet
                                    patient's
                                    nee".
                                    Lila
                                    Saskumar<br/><br/>I
                                    wrenched
                                    my
                                    shoulder
                                    badly
                                    getting
                                    off
                                    a
                                    coach
                                    and
                                    started
                                    to
                                    get
                                    pain
                                    in
                                    my
                                    shoulders,
                                    neck
                                    and
                                    back.
                                    When
                                    I
                                    was
                                    referred
                                    to
                                    the
                                    hospital,
                                    they
                                    did
                                    not
                                    take
                                    my
                                    injury
                                    seriously
                                    attributing
                                    the
                                    cause
                                    to
                                    an
                                    old
                                    whiplash
                                    injury,
                                    and
                                    advising
                                    that
                                    I
                                    would
                                    never
                                    get
                                    any
                                    better.
                                    I
                                    had
                                    physiotherapy
                                    at
                                    the
                                    hospital
                                    but
                                    it
                                    was
                                    a
                                    waste
                                    of
                                    time.
                                    However,
                                    I
                                    was
                                    determined
                                    to
                                    be
                                    well
                                    and
                                    decided
                                    to
                                    use
                                    an
                                    osteopath,
                                    which
                                    gave
                                    temporary
                                    relief.
                                    Over
                                    time,
                                    even
                                    the
                                    osteopathy
                                    stopped
                                    working
                                    and
                                    I
                                    started
                                    to
                                    feel
                                    ill
                                    especially
                                    after
                                    exercise.
                                    I
                                    had
                                    a
                                    friend
                                    who
                                    strongly
                                    recommended
                                    going
                                    to
                                    Croydon
                                    Physiotherapy,
                                    where
                                    I
                                    saw
                                    Tim.
                                    The
                                    consultation
                                    was
                                    amazing!
                                    Tim
                                    took
                                    my
                                    symptoms
                                    seriously
                                    and
                                    immediately
                                    identified
                                    that
                                    there
                                    was
                                    a
                                    rotator
                                    cuff
                                    injury.
                                    He
                                    also
                                    suspected
                                    that
                                    I
                                    needed
                                    an
                                    operation
                                    and
                                    recommended
                                    an
                                    MRI
                                    scan.
                                    When
                                    the
                                    results
                                    came
                                    back
                                    it
                                    showed
                                    that
                                    I
                                    did
                                    indeed
                                    need
                                    an
                                    operation.
                                    In
                                    fact,
                                    the
                                    build
                                    up
                                    of
                                    bone
                                    was
                                    starting
                                    to
                                    damage
                                    the
                                    tendons
                                    in
                                    the
                                    joint.
                                    I
                                    hate
                                    to
                                    think
                                    how
                                    debilitating
                                    my
                                    injuries
                                    would
                                    be
                                    now
                                    if
                                    I
                                    had
                                    not
                                    come
                                    in
                                    contact
                                    with
                                    Tim
                                    and
                                    his
                                    team.
                                    I
                                    had
                                    the
                                    operation,
                                    which
                                    was
                                    a
                                    success.
                                    I
                                    feel
                                    much
                                    better
                                    and
                                    I
                                    am
                                    able
                                    to
                                    be
                                    far
                                    more
                                    independent
                                    than
                                    before
                                    having
                                    the
                                    operation.
                                    Tim
                                    and
                                    his
                                    team
                                    are
                                    so
                                    professional,
                                    knowledgeable
                                    and
                                    caring.
                                    I
                                    would
                                    strongly
                                    recommend
                                    anyone
                                    who
                                    has
                                    suffered
                                    a
                                    musculoskeletal
                                    injury
                                    to
                                    go
                                    to
                                    Croydon
                                    Physio.<br/></em><strong>~Doreeen
                                    Edwards</strong>
                            </div>
                            <br/>

                            <div><em>I had a
                                tremendously painful
                                right shoulder, it came
                                out of nowhere - I
                                couldn't move it and it
                                was
                                extremely painful, I
                                went to A&amp;E which
                                showed what seemed to be
                                an extra piece of bone
                                on my shoulder. My GP
                                hadn't really got a clue
                                and was
                                referring me to the
                                specialist, which was
                                going to take several
                                weeks. I went to see
                                Julie at Surrey physio.
                                I explained the issue,
                                she instantly put
                                me at ease - being my
                                first time at
                                a
                                physio, she asked a lot
                                of questions and she
                                suspected a build up of
                                calcium deposits from
                                when I played
                                county badminton - over
                                20 years
                                previously, which the
                                specialist finally
                                confirmed a few weeks
                                later. After a series of
                                treatments with
                                Julie and exercises she
                                gave me to do at
                                home the pain left me
                                very fast. By the 2nd
                                time I saw the
                                specialist the calcium
                                deposits had gone (after
                                they had said I would l
                                probably need
                                an operation to scrape
                                it off my bone) and I
                                had full working use of
                                my am again. The
                                specialists were
                                surprised to see it had
                                disappeared
                                entirely and they said
                                that the only potential
                                explanation for this was
                                the physio treatment
                                that I had been
                                receiving as I was only
                                taking
                                painkillers to that
                                point.<br/>Julie was
                                fantastic, she
                                always understood my
                                pain threshold, firm
                                but
                                gentle, she
                                developed my
                                treatments as I
                                got better and she
                                was always very
                                friendly and
                                professional, as
                                were the whole gang
                                there whenever I saw
                                them. In a weird way
                                I wish I still
                                had
                                some pain so I could
                                keep going. You
                                don't get that often
                                - people wanting to
                                have pain to be able
                                to go and
                                see their
                                physiotherapist. I
                                would
                                highly recommend
                                Surrey Physio. They
                                saved my pain, they
                                saved me having an
                                operation and a scar
                                for life.
                                Thank you Julie and
                                the Gang at
                                Surrey
                                Physio.</em><br/><strong>~Robert
                                Allan</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Page>);
    }
}
export default Testimonials;