import React, {useEffect, useState} from 'react';

import Header from "./components/Header";
import HomePage from  './components/home/home_page';
import Footer from "./components/Footer";
import About from "./components/About/about";

import News from "./components/News/news";
import MeetTeem from "./components/MeetTeem/meetTeem";

import Cost from "./components/Cost/cost";
import Testimonials from "./components/Testimonials/testimonials";
import Olympics from "./components/Olympics/olympics";
import Contact from "./components/Contact/contact";

import Gait from "./components/Cps/gait";
import Pilates from "./components/Cps/pilates";
import './access/css/libs.css';
import './access/css/main.css';
import './access/css/media.css';

import {
    HashRouter,
    BrowserRouter,
    Route
} from 'react-router-dom';

function App() {

    return (
        <HashRouter>
            <div className="App">
                <Header/>
                <Route exact path='/' component={HomePage} />
                <Route path='/about' component={About} />
                <Route path='/news' component={News} />
                <Route path='/meet_teem' component={MeetTeem} />

                <Route path='/pilates' component={Pilates} />
                {/*<Route path='/gait' component={Gait} />*/}

                <Route path='/cost' component={Cost} />
                <Route path='/testimonials' component={Testimonials} />
                <Route path='/olympics' component={Olympics} />
                <Route path='/contact' component={Contact} />
                {/*<Route path='/pilates' component={Pilates} />*/}
                <Footer/>
            </div>
         </HashRouter>
    );

}

export default App;
