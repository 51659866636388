import React from 'react';
import Page from "../module/include/Page/Page";


class about extends React.Component {
// const  about = () => {
    state = {
        title: 'About us'
    };
    render() {
        return (<Page title={this.state.title}>
            <section className="aboutUs">
                <div className="container">
                    <h2 className="sectionTitle">About us</h2>
                    <div className="aboutUs_content">
                        <div className="aboutUs__advantages">
                            Croydon Physio was set up in 2005 by Tim Allardyce to provide the local community in Croydon
                            with access to high-quality physiotherapy care.
                        </div>
                        <br/><br/>
                        <div className="aboutUs__advantages">
                            <div>Running Experts and Marathon Injury Clinic</div>
                            <br/><br/>
                            We started small, and grew to become a bigger group and in 2008 we became Surrey Physio. In
                            2009
                            we picked up our first NHS contract in Sutton, and in 2012 we picked up more NHS contracts
                            in
                            Wandsworth and North-East Hampshire. In 2016, we provided a pilot in Croydon where NHS
                            patients
                            could get treatment from our team in Selsdon, and we treated over 10,000 patients free of
                            charge. We had many notable results, including a number of patients who did not require
                            surgery
                            after attending our clinic for a course of rehabilitation and treatment.
                        </div>
                        <br/><br/>
                        <div className="aboutUs__advantages">
                            <div>Massage Therapy and Sports Massage</div>
                            <br/><br/>
                            In 2020, during COVID, we placed physiotherapists into almost every GP surgery in Croydon in
                            a
                            National scheme to reduce pressure on GP practices. Although under this process we cannot
                            provide hands-on NHS treatment, we can provide advice, guidance and exercises at your GP
                            surgery
                            (although we are not the physiotherapy provider in Croydon, this is run by a different
                            service).
                        </div>
                        <br/><br/>
                        <div className="aboutUs__advantages">
                            <div>Acupuncture</div>
                            <br/><br/>
                            In 2021, we started vaccinating, mostly at Fairfield Halls but also at some other sites
                            including St Paul's Church in Thornton Heath, and other areas including Mitcham, Streatham,
                            Balham, Putney, Dagenham, and Farnborough. Our incredible team vaccinated over 12,000
                            people.
                        </div>
                    </div>
                </div>
            </section>

            <section className="experience">
                <div className="container">
                    <div className="experience__item">
                        <div className="experience__item--title">
                            Work Experience:
                        </div>
                        <div className="experience__item--desc">
                            We used to take work experience students under 18 but now we don’t, so we only take students
                            over the age of 18 who are at undergraduate level. Contact us for more information.
                        </div>
                    </div>
                    <div className="experience__item">
                        <div className="experience__item--title">
                            Surrey Physio:
                        </div>
                        <div className="experience__item--desc">
                            Our sister clinic is Surrey Physio and its basically the same organisation as Croydon
                            Physio.
                            Tim took
                            over Surrey Physio in 2008 from its previous owners, three years after opening Croydon
                            Physio.
                            It has
                            now grown and we are all like one big team. Because we established such a good reputation in
                            the
                            local
                            area, we kept the name Croydon Physio.
                        </div>
                    </div>
                    <div className="experience__item">
                        <div className="experience__item--title">
                            Local Clinics:
                        </div>
                        <div className="experience__item--desc">
                            Locally we are based at Parkside Group Practice, S.Croydon, and East Croydon Medical Centre,
                            as
                            well as
                            our two bases at 1 Upper Selsdon Rd, and 409-411 London Road, Mitcham. So we pretty much
                            have
                            Croydon
                            covered. If you need physiotherapy or osteopathy in Croydon, please consider using us as
                            your
                            first
                            choice clinic.
                        </div>
                    </div>
                </div>
            </section>
        </Page>);
    }
}
export default about;