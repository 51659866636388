import React from 'react';
import houseIcon from '../../access/img/houseIcon.svg';
const  HomeBlock1 = () => {
    return <section className="welcome">
        <div className="container">
            <h2 className="sectionTitle">Croydon Physio – Welcome to our site!</h2>
            <div className="welcomeSlogan">WE DON'T JUST TREAT SPORTS INJURIES</div>
            <div className="welcomeAddress">
                <div className="welcomeAddress__title">OUR FRIENDLY CLINIC:</div>
                <div className="welcomeAddress__desc">
                    <img src={houseIcon} alt="" />
                    WE ARE BASED at 1 Upper Selsdon Rd, Croydon CR2 8DD. Our telephone number is <span className="bold">0208 651 3315.</span>.
                </div>
            </div>
        </div>
    </section>
}
export default HomeBlock1;